import { LimitInfo } from 'vue'

export class Desk {
    tableNo!: number
    gameSet!: number
    currentTime!: number
    isReserved = false
    limitInfo: LimitInfo = {}
    ju!: number
    gameType!: number
    gameId!: string
    bankerBet = 0
    playerBet = 0
    dragonBet = 0
    tigerBet = 0
    redBet = 0
    blueBet = 0
    tieBet = 0
    redPairBet = 0
    bluePairBet = 0
    status!: number
    name!: string
    balance!: number
    notice!: string
    result!: string
    allBet = 0
    f1 = 0
    f2 = 0
    f3 = 0
    f4 = 0
    f5 = 0
    f6 = 0
    f7 = 0
    f8 = 0
    f9 = 0
    f10 = 0
    f11 = 0
    f12 = 0
    f13 = 0
    f14 = 0
    f15 = 0
    f16 = 0
    f17 = 0
    f18 = 0
    f19 = 0
    f20 = 0
    f21 = 0
    f22 = 0
    f23 = 0
    f24 = 0
    f25 = 0
    f26 = 0
    f27 = 0
    f28 = 0
    f29 = 0
    f30 = 0
    f31 = 0
    f32 = 0
    f33 = 0
    f34 = 0
    f35 = 0
    f36 = 0
    f37 = 0
    f38 = 0
    f39 = 0
    f40 = 0
    f41 = 0
    f42 = 0

    plimit = '0-0'
    alimit = '0-0'

    sums = 0

    sumd = 0
    sum1 = 0
    sum2 = 0
    sum3 = 0
    sum4 = 0
    allsums = 0
    allsumd = 0
    allsum1 = 0
    allsum2 = 0
    allsum3 = 0
    allsum4 = 0

    /**
     * Parse Single Desk Data
     * @param data
     */
    public static Parse(data: string): Desk {
        const _desk = new Desk()
        _desk.limitInfo = {}
        const _keyValues = data.split('&')

        _keyValues.forEach((item) => {
            if (item.startsWith('mdesk=')) {
                _desk.tableNo = parseInt(item.split('=')[1])
            } else if (item.startsWith('xue=')) {
                _desk.gameSet = parseInt(item.split('=')[1])
            } else if (item.startsWith('xztime=')) {
                _desk.currentTime = parseInt(item.split('=')[1])
            } else if (item.startsWith('baotai=')) {
                _desk.isReserved = item.split('=')[1] === '1'
            } else if (item.startsWith('downLimit=')) {
                _desk.limitInfo.minBet = parseInt(item.split('=')[1])
            } else if (item.startsWith('upLimit=')) {
                _desk.limitInfo.maxBet = parseInt(item.split('=')[1])
            } else if (item.startsWith('ju=')) {
                _desk.ju = parseInt(item.split('=')[1])
            } else if (item.startsWith('gameType=')) {
                _desk.gameType = parseInt(item.split('=')[1])
            } else if (item.startsWith('GameID=')) {
                _desk.gameId = String(item.split('=')[1])
            } else if (item.startsWith('y_z=')) {
                _desk.redBet = parseInt(item.split('=')[1]) ?? 0
            } else if (item.startsWith('y_x=')) {
                _desk.blueBet = parseInt(item.split('=')[1]) ?? 0
            } else if (item.startsWith('y_h=')) {
                _desk.tieBet = parseInt(item.split('=')[1]) ?? 0
            } else if (item.startsWith('y_zd=')) {
                // red pair
                _desk.redPairBet = parseInt(item.split('=')[1]) ?? 0
            } else if (item.startsWith('y_xd=')) {
                // blue pair
                _desk.bluePairBet = parseInt(item.split('=')[1]) ?? 0
            } else if (item.startsWith('UserPoint=')) {
                _desk.balance = parseInt(item.split('=')[1]) ?? 0
            } else if (item.startsWith('gonggao=')) {
                _desk.notice = item.split('=')[1]
            } else if (item.startsWith('gameStatus=')) {
                _desk.status = parseInt(item.split('=')[1])
            } else if (item.startsWith('result=')) {
                const _itemTemp = item.split('=')
                if (_itemTemp.length > 1) {
                    _desk.result = _itemTemp[1]
                } else {
                    _desk.result = ''
                }
            } else if (item.startsWith('ShowName=')) {
                _desk.name = item.split('=')[1]
            } else if (item.startsWith('tieDonwLimit=')) {
                _desk.limitInfo.tieMinBet = parseInt(item.split('=')[1])
            } else if (item.startsWith('tieUpLimit=')) {
                _desk.limitInfo.tieMaxBet = parseInt(item.split('=')[1])
            } else if (item.startsWith('pairDonwLimit=')) {
                _desk.limitInfo.pairMinBet = parseInt(item.split('=')[1])
            } else if (item.startsWith('pairUpLimit=')) {
                _desk.limitInfo.pairMaxBet = parseInt(item.split('=')[1])
            } else if (item.startsWith('f1=')) {
                _desk.f1 = parseInt(item.split('=')[1])
            } else if (item.startsWith('f2=')) {
                _desk.f2 = parseInt(item.split('=')[1])
            } else if (item.startsWith('f3=')) {
                _desk.f3 = parseInt(item.split('=')[1])
            } else if (item.startsWith('f4=')) {
                _desk.f4 = parseInt(item.split('=')[1])
            } else if (item.startsWith('f5=')) {
                _desk.f5 = parseInt(item.split('=')[1])
            } else if (item.startsWith('f6=')) {
                _desk.f6 = parseInt(item.split('=')[1])
            } else if (item.startsWith('f7=')) {
                _desk.f7 = parseInt(item.split('=')[1])
            } else if (item.startsWith('f8=')) {
                _desk.f8 = parseInt(item.split('=')[1])
            } else if (item.startsWith('f9=')) {
                _desk.f9 = parseInt(item.split('=')[1])
            } else if (item.startsWith('f10=')) {
                _desk.f10 = parseInt(item.split('=')[1])
            } else if (item.startsWith('f11=')) {
                _desk.f11 = parseInt(item.split('=')[1])
            } else if (item.startsWith('f12=')) {
                _desk.f12 = parseInt(item.split('=')[1])
            } else if (item.startsWith('f13=')) {
                _desk.f13 = parseInt(item.split('=')[1])
            } else if (item.startsWith('f14=')) {
                _desk.f14 = parseInt(item.split('=')[1])
            } else if (item.startsWith('f15=')) {
                _desk.f15 = parseInt(item.split('=')[1])
            } else if (item.startsWith('f16=')) {
                _desk.f16 = parseInt(item.split('=')[1])
            } else if (item.startsWith('f17=')) {
                _desk.f17 = parseInt(item.split('=')[1])
            } else if (item.startsWith('f18=')) {
                _desk.f18 = parseInt(item.split('=')[1])
            } else if (item.startsWith('f19=')) {
                _desk.f19 = parseInt(item.split('=')[1])
            } else if (item.startsWith('f20=')) {
                _desk.f20 = parseInt(item.split('=')[1])
            } else if (item.startsWith('f21=')) {
                _desk.f21 = parseInt(item.split('=')[1])
            } else if (item.startsWith('f22=')) {
                _desk.f22 = parseInt(item.split('=')[1])
            } else if (item.startsWith('f23=')) {
                _desk.f23 = parseInt(item.split('=')[1])
            } else if (item.startsWith('f24=')) {
                _desk.f24 = parseInt(item.split('=')[1])
            } else if (item.startsWith('f25=')) {
                _desk.f25 = parseInt(item.split('=')[1])
            } else if (item.startsWith('f26=')) {
                _desk.f26 = parseInt(item.split('=')[1])
            } else if (item.startsWith('f27=')) {
                _desk.f27 = parseInt(item.split('=')[1])
            } else if (item.startsWith('f28=')) {
                _desk.f28 = parseInt(item.split('=')[1])
            } else if (item.startsWith('f29=')) {
                _desk.f29 = parseInt(item.split('=')[1])
            } else if (item.startsWith('f30=')) {
                _desk.f30 = parseInt(item.split('=')[1])
            } else if (item.startsWith('f31=')) {
                _desk.f31 = parseInt(item.split('=')[1])
            } else if (item.startsWith('f32=')) {
                _desk.f32 = parseInt(item.split('=')[1])
            } else if (item.startsWith('f33=')) {
                _desk.f33 = parseInt(item.split('=')[1])
            } else if (item.startsWith('f34=')) {
                _desk.f34 = parseInt(item.split('=')[1])
            } else if (item.startsWith('f35=')) {
                _desk.f35 = parseInt(item.split('=')[1])
            } else if (item.startsWith('f36=')) {
                _desk.f36 = parseInt(item.split('=')[1])
            } else if (item.startsWith('f37=')) {
                _desk.f37 = parseInt(item.split('=')[1])
            } else if (item.startsWith('f38=')) {
                _desk.f38 = parseInt(item.split('=')[1])
            } else if (item.startsWith('f39=')) {
                _desk.f39 = parseInt(item.split('=')[1])
            } else if (item.startsWith('f40=')) {
                _desk.f40 = parseInt(item.split('=')[1])
            } else if (item.startsWith('f41=')) {
                _desk.f41 = parseInt(item.split('=')[1])
            } else if (item.startsWith('f42=')) {
                _desk.f42 = parseInt(item.split('=')[1])
            } else if (item.startsWith('allbet=')) {
                _desk.allBet = parseInt(item.split('=')[1])
            } else if (item.startsWith('plimit=')) {
                _desk.plimit = item.split('=')[1]
            } else if (item.startsWith('alimit=')) {
                _desk.alimit = item.split('=')[1]
            } else if (item.startsWith('sums=')) {
                _desk.sums = parseInt(item.split('=')[1])
            } else if (item.startsWith('sumd=')) {
                _desk.sumd = parseInt(item.split('=')[1])
            } else if (item.startsWith('sum1=')) {
                _desk.sum1 = parseInt(item.split('=')[1])
            } else if (item.startsWith('sum2=')) {
                _desk.sum2 = parseInt(item.split('=')[1])
            } else if (item.startsWith('sum3=')) {
                _desk.sum3 = parseInt(item.split('=')[1])
            } else if (item.startsWith('sum4=')) {
                _desk.sum4 = parseInt(item.split('=')[1])
            } else if (item.startsWith('allsums=')) {
                _desk.allsums = parseInt(item.split('=')[1])
            } else if (item.startsWith('allsumd=')) {
                _desk.allsumd = parseInt(item.split('=')[1])
            } else if (item.startsWith('allsum1=')) {
                _desk.allsum1 = parseInt(item.split('=')[1])
            } else if (item.startsWith('allsum2=')) {
                _desk.allsum2 = parseInt(item.split('=')[1])
            } else if (item.startsWith('allsum3=')) {
                _desk.allsum3 = parseInt(item.split('=')[1])
            } else if (item.startsWith('allsum4=')) {
                _desk.allsum4 = parseInt(item.split('=')[1])
            }
        })

        return _desk
    }

    /**
     * Parse List Desk Data
     * @param strData
     */
    public static ParseList(strData: string): Desk[] {
        const _result: Desk[] = []

        if (strData === '') return _result

        const _keyValues = strData.split('$')
        _keyValues.pop()

        _keyValues.forEach((item) => {
            _result.push(Desk.Parse(item))
        })

        return _result
    }
}
