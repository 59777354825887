import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '@/assets/imgs/pc/bet_no_edge_bg.png'
import _imports_1 from '@/assets/imgs/pc/left-top-edge.png'
import _imports_2 from '@/assets/imgs/pc/left-bottom-edge.png'
import _imports_3 from '@/assets/imgs/ops.png'
import _imports_4 from '@/assets/imgs/pc/bottom_left_edge.png'
import _imports_5 from '@/assets/imgs/pc/top-right-edge.png'
import _imports_6 from '@/assets/imgs/pc/bottom-right-edge.png'
import _imports_7 from '@/assets/imgs/pc/right-top-edge.png'
import _imports_8 from '@/assets/imgs/pc/right-bottom-edge.png'


const _withScopeId = n => (_pushScopeId("data-v-5117c89d"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "bet-group-root" }
const _hoisted_2 = { class: "c1" }
const _hoisted_3 = ["onMousedown", "onMouseup"]
const _hoisted_4 = ["onMousedown", "onMouseup"]
const _hoisted_5 = ["onMousedown", "onMouseup"]
const _hoisted_6 = ["onMousedown", "onMouseup"]
const _hoisted_7 = { class: "c2" }
const _hoisted_8 = ["onMousedown", "onMouseup"]
const _hoisted_9 = ["onMousedown", "onMouseup"]
const _hoisted_10 = ["onMousedown", "onMouseup"]
const _hoisted_11 = ["onMousedown", "onMouseup"]
const _hoisted_12 = ["onMousedown", "onMouseup"]
const _hoisted_13 = { class: "c3" }
const _hoisted_14 = { class: "c3_1" }
const _hoisted_15 = ["onMousedown", "onMouseup"]
const _hoisted_16 = ["onMousedown", "onMouseup"]
const _hoisted_17 = ["onMousedown", "onMouseup"]
const _hoisted_18 = { class: "c3_2" }
const _hoisted_19 = { class: "c3_2_1" }
const _hoisted_20 = ["onMousedown", "onMouseup"]
const _hoisted_21 = ["onMousedown", "onMouseup"]
const _hoisted_22 = { class: "c3_2_1" }
const _hoisted_23 = ["onMousedown", "onMouseup"]
const _hoisted_24 = ["onMousedown", "onMouseup"]
const _hoisted_25 = ["onMousedown", "onMouseup"]
const _hoisted_26 = { class: "c3_2_1" }
const _hoisted_27 = ["onMousedown", "onMouseup"]
const _hoisted_28 = ["onMousedown", "onMouseup"]
const _hoisted_29 = ["onMousedown", "onMouseup"]
const _hoisted_30 = { class: "c3_2_1" }
const _hoisted_31 = ["onMousedown", "onMouseup"]
const _hoisted_32 = ["onMousedown", "onMouseup"]
const _hoisted_33 = { class: "c3_1" }
const _hoisted_34 = ["onMousedown", "onMouseup"]
const _hoisted_35 = ["onMousedown", "onMouseup"]
const _hoisted_36 = ["onMousedown", "onMouseup"]
const _hoisted_37 = { class: "c4" }
const _hoisted_38 = ["onMousedown", "onMouseup"]
const _hoisted_39 = ["onMousedown", "onMouseup"]
const _hoisted_40 = ["onMousedown", "onMouseup"]
const _hoisted_41 = ["onMousedown", "onMouseup"]
const _hoisted_42 = ["onMousedown", "onMouseup"]
const _hoisted_43 = { class: "c5" }
const _hoisted_44 = ["onMousedown", "onMouseup"]
const _hoisted_45 = ["onMousedown", "onMouseup"]
const _hoisted_46 = ["onMousedown", "onMouseup"]
const _hoisted_47 = ["onMousedown", "onMouseup"]
const _hoisted_48 = ["onMousedown", "onMouseup"]
const _hoisted_49 = ["onMousedown", "onMouseup"]
const _hoisted_50 = { class: "c6" }
const _hoisted_51 = ["onMousedown", "onMouseup"]
const _hoisted_52 = ["onMousedown", "onMouseup"]
const _hoisted_53 = ["onMousedown", "onMouseup"]
const _hoisted_54 = ["onMousedown", "onMouseup"]
const _hoisted_55 = ["onMousedown", "onMouseup"]
const _hoisted_56 = ["onMousedown", "onMouseup"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_bet_box = _resolveComponent("bet-box")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_bet_box, {
        class: "r27",
        label: '123中',
        "bet-type": '123中',
        amount: _ctx.temp27,
        "confirmed-bet": _ctx.f27,
        "f-temp-bet": _ctx.ftemp27,
        color: 1,
        ratio: 0.3167,
        "show-animation": _ctx.showResult('f27'),
        onPlaceBet: _ctx.onPlaceBet
      }, {
        custombutton: _withCtx(({ onClick, onRelease }) => [
          _createElementVNode("img", {
            src: _imports_0,
            onMousedown: ($event: any) => (onClick($event)),
            onMouseup: ($event: any) => (onRelease()),
            onContextmenu: _cache[0] || (_cache[0] = (e) => e.preventDefault())
          }, null, 40, _hoisted_3)
        ]),
        _: 1
      }, 8, ["amount", "confirmed-bet", "f-temp-bet", "ratio", "show-animation", "onPlaceBet"]),
      _createVNode(_component_bet_box, {
        class: "r27",
        label: '124中',
        "bet-type": '124中',
        amount: _ctx.temp28,
        "confirmed-bet": _ctx.f28,
        "f-temp-bet": _ctx.ftemp28,
        color: 1,
        ratio: 0.3167,
        "show-animation": _ctx.showResult('f28'),
        onPlaceBet: _ctx.onPlaceBet
      }, {
        custombutton: _withCtx(({ onClick, onRelease }) => [
          _createElementVNode("img", {
            src: _imports_0,
            onMousedown: ($event: any) => (onClick($event)),
            onMouseup: ($event: any) => (onRelease()),
            onContextmenu: _cache[1] || (_cache[1] = (e) => e.preventDefault())
          }, null, 40, _hoisted_4)
        ]),
        _: 1
      }, 8, ["amount", "confirmed-bet", "f-temp-bet", "ratio", "show-animation", "onPlaceBet"]),
      _createVNode(_component_bet_box, {
        class: "r27",
        label: '134中',
        "bet-type": '134中',
        amount: _ctx.temp29,
        "confirmed-bet": _ctx.f29,
        "f-temp-bet": _ctx.ftemp29,
        color: 1,
        ratio: 0.3167,
        "show-animation": _ctx.showResult('f29'),
        onPlaceBet: _ctx.onPlaceBet
      }, {
        custombutton: _withCtx(({ onClick, onRelease }) => [
          _createElementVNode("img", {
            src: _imports_0,
            onMousedown: ($event: any) => (onClick($event)),
            onMouseup: ($event: any) => (onRelease()),
            onContextmenu: _cache[2] || (_cache[2] = (e) => e.preventDefault())
          }, null, 40, _hoisted_5)
        ]),
        _: 1
      }, 8, ["amount", "confirmed-bet", "f-temp-bet", "ratio", "show-animation", "onPlaceBet"]),
      _createVNode(_component_bet_box, {
        class: "fillH no-margin",
        label: '234中',
        "bet-type": '234中',
        amount: _ctx.temp30,
        "confirmed-bet": _ctx.f30,
        "f-temp-bet": _ctx.ftemp30,
        color: 1,
        ratio: 0.3167,
        "show-animation": _ctx.showResult('f30'),
        onPlaceBet: _ctx.onPlaceBet
      }, {
        custombutton: _withCtx(({ onClick, onRelease }) => [
          _createElementVNode("img", {
            src: _imports_0,
            onMousedown: ($event: any) => (onClick($event)),
            onMouseup: ($event: any) => (onRelease()),
            onContextmenu: _cache[3] || (_cache[3] = (e) => e.preventDefault())
          }, null, 40, _hoisted_6)
        ]),
        _: 1
      }, 8, ["amount", "confirmed-bet", "f-temp-bet", "ratio", "show-animation", "onPlaceBet"])
    ]),
    _createElementVNode("div", _hoisted_7, [
      _createVNode(_component_bet_box, {
        class: "r20",
        label: '34角',
        "bet-type": '34角',
        amount: _ctx.temp22,
        "confirmed-bet": _ctx.f22,
        "f-temp-bet": _ctx.ftemp22,
        color: 2,
        ratio: 0.95,
        "show-animation": _ctx.showResult('f22'),
        onPlaceBet: _ctx.onPlaceBet
      }, {
        custombutton: _withCtx(({ onClick, onRelease }) => [
          _createElementVNode("img", {
            src: _imports_0,
            onMousedown: ($event: any) => (onClick($event)),
            onMouseup: ($event: any) => (onRelease()),
            onContextmenu: _cache[4] || (_cache[4] = (e) => e.preventDefault())
          }, null, 40, _hoisted_8)
        ]),
        _: 1
      }, 8, ["amount", "confirmed-bet", "f-temp-bet", "ratio", "show-animation", "onPlaceBet"]),
      _createVNode(_component_bet_box, {
        class: "r20",
        label: '4连3',
        "bet-type": '4连3',
        amount: _ctx.temp16,
        "confirmed-bet": _ctx.f16,
        "f-temp-bet": _ctx.ftemp16,
        color: 3,
        ratio: 1.9,
        "show-animation": _ctx.showResult('f16'),
        onPlaceBet: _ctx.onPlaceBet
      }, {
        custombutton: _withCtx(({ onClick, onRelease }) => [
          _createElementVNode("img", {
            src: _imports_0,
            onMousedown: ($event: any) => (onClick($event)),
            onMouseup: ($event: any) => (onRelease()),
            onContextmenu: _cache[5] || (_cache[5] = (e) => e.preventDefault())
          }, null, 40, _hoisted_9)
        ]),
        _: 1
      }, 8, ["amount", "confirmed-bet", "f-temp-bet", "ratio", "show-animation", "onPlaceBet"]),
      _createVNode(_component_bet_box, {
        class: "fillH",
        label: '4正',
        "bet-type": '4正',
        amount: _ctx.temp26,
        "confirmed-bet": _ctx.f26,
        "f-temp-bet": _ctx.ftemp26,
        color: 4,
        ratio: 0.95,
        "show-animation": _ctx.showResult('f26'),
        onPlaceBet: _ctx.onPlaceBet
      }, {
        custombutton: _withCtx(({ onClick, onRelease }) => [
          _createElementVNode("img", {
            src: _imports_0,
            onMousedown: ($event: any) => (onClick($event)),
            onMouseup: ($event: any) => (onRelease()),
            onContextmenu: _cache[6] || (_cache[6] = (e) => e.preventDefault())
          }, null, 40, _hoisted_10)
        ]),
        _: 1
      }, 8, ["amount", "confirmed-bet", "f-temp-bet", "ratio", "show-animation", "onPlaceBet"]),
      _createVNode(_component_bet_box, {
        class: "r20",
        label: '4连1',
        "bet-type": '4连1',
        amount: _ctx.temp14,
        "confirmed-bet": _ctx.f14,
        "f-temp-bet": _ctx.ftemp14,
        color: 3,
        ratio: 1.9,
        "show-animation": _ctx.showResult('f14'),
        onPlaceBet: _ctx.onPlaceBet
      }, {
        custombutton: _withCtx(({ onClick, onRelease }) => [
          _createElementVNode("img", {
            src: _imports_0,
            onMousedown: ($event: any) => (onClick($event)),
            onMouseup: ($event: any) => (onRelease()),
            onContextmenu: _cache[7] || (_cache[7] = (e) => e.preventDefault())
          }, null, 40, _hoisted_11)
        ]),
        _: 1
      }, 8, ["amount", "confirmed-bet", "f-temp-bet", "ratio", "show-animation", "onPlaceBet"]),
      _createVNode(_component_bet_box, {
        class: "r20 no-margin",
        label: '14角',
        "bet-type": '14角',
        amount: _ctx.temp19,
        "confirmed-bet": _ctx.f19,
        "f-temp-bet": _ctx.ftemp19,
        color: 2,
        ratio: 0.95,
        "show-animation": _ctx.showResult('f19'),
        onPlaceBet: _ctx.onPlaceBet
      }, {
        custombutton: _withCtx(({ onClick, onRelease }) => [
          _createElementVNode("img", {
            src: _imports_0,
            onMousedown: ($event: any) => (onClick($event)),
            onMouseup: ($event: any) => (onRelease()),
            onContextmenu: _cache[8] || (_cache[8] = (e) => e.preventDefault())
          }, null, 40, _hoisted_12)
        ]),
        _: 1
      }, 8, ["amount", "confirmed-bet", "f-temp-bet", "ratio", "show-animation", "onPlaceBet"])
    ]),
    _createElementVNode("div", _hoisted_13, [
      _createElementVNode("div", _hoisted_14, [
        _createVNode(_component_bet_box, {
          class: "c3_1_25 no-margin",
          label: '3连4',
          "bet-type": '3连4',
          amount: _ctx.temp13,
          "confirmed-bet": _ctx.f13,
          "f-temp-bet": _ctx.ftemp13,
          color: 3,
          ratio: 1.9,
          "show-animation": _ctx.showResult('f13'),
          onPlaceBet: _ctx.onPlaceBet
        }, {
          custombutton: _withCtx(({ onClick, onRelease }) => [
            _createElementVNode("img", {
              src: _imports_0,
              onMousedown: ($event: any) => (onClick($event)),
              onMouseup: ($event: any) => (onRelease()),
              onContextmenu: _cache[9] || (_cache[9] = (e) => e.preventDefault())
            }, null, 40, _hoisted_15)
          ]),
          _: 1
        }, 8, ["amount", "confirmed-bet", "f-temp-bet", "ratio", "show-animation", "onPlaceBet"]),
        _createVNode(_component_bet_box, {
          class: "c3_1_fillW",
          label: '3正',
          "bet-type": '3正',
          amount: _ctx.temp25,
          "confirmed-bet": _ctx.f25,
          "f-temp-bet": _ctx.ftemp25,
          color: 4,
          ratio: 0.95,
          "show-animation": _ctx.showResult('f25'),
          onPlaceBet: _ctx.onPlaceBet
        }, {
          custombutton: _withCtx(({ onClick, onRelease }) => [
            _createElementVNode("img", {
              src: _imports_0,
              onMousedown: ($event: any) => (onClick($event)),
              onMouseup: ($event: any) => (onRelease()),
              onContextmenu: _cache[10] || (_cache[10] = (e) => e.preventDefault())
            }, null, 40, _hoisted_16)
          ]),
          _: 1
        }, 8, ["amount", "confirmed-bet", "f-temp-bet", "ratio", "show-animation", "onPlaceBet"]),
        _createVNode(_component_bet_box, {
          class: "c3_1_25",
          label: '3连2',
          "bet-type": '3连2',
          amount: _ctx.temp12,
          "confirmed-bet": _ctx.f12,
          "f-temp-bet": _ctx.ftemp12,
          color: 3,
          ratio: 1.9,
          "show-animation": _ctx.showResult('f12'),
          onPlaceBet: _ctx.onPlaceBet
        }, {
          custombutton: _withCtx(({ onClick, onRelease }) => [
            _createElementVNode("img", {
              src: _imports_0,
              onMousedown: ($event: any) => (onClick($event)),
              onMouseup: ($event: any) => (onRelease()),
              onContextmenu: _cache[11] || (_cache[11] = (e) => e.preventDefault())
            }, null, 40, _hoisted_17)
          ]),
          _: 1
        }, 8, ["amount", "confirmed-bet", "f-temp-bet", "ratio", "show-animation", "onPlaceBet"])
      ]),
      _createElementVNode("div", _hoisted_18, [
        _createElementVNode("div", null, [
          _createElementVNode("div", _hoisted_19, [
            _createVNode(_component_bet_box, {
              class: "c3_2_1_1 left-top-edge",
              label: '4番',
              "bet-type": '4番',
              amount: _ctx.temp4,
              "confirmed-bet": _ctx.f4,
              "f-temp-bet": _ctx.ftemp4,
              color: 1,
              ratio: 2.85,
              "show-animation": _ctx.showResult('f4'),
              alignment: 'bottom',
              ref: "leftTopEdge",
              onPlaceBet: _ctx.onPlaceBet
            }, {
              custombutton: _withCtx(({ onClick, onRelease }) => [
                _createElementVNode("img", {
                  src: _imports_1,
                  onMousedown: ($event: any) => (onClick($event)),
                  onMouseup: ($event: any) => (onRelease()),
                  onContextmenu: _cache[12] || (_cache[12] = (e) => e.preventDefault())
                }, null, 40, _hoisted_20)
              ]),
              _: 1
            }, 8, ["amount", "confirmed-bet", "f-temp-bet", "ratio", "show-animation", "onPlaceBet"]),
            _createVNode(_component_bet_box, {
              class: "c3_2_1_1 left-bottom-edge",
              label: '4连2',
              "bet-type": '4连2',
              amount: _ctx.temp15,
              "confirmed-bet": _ctx.f15,
              "f-temp-bet": _ctx.ftemp15,
              color: 3,
              ratio: 1.9,
              "show-animation": _ctx.showResult('f15'),
              alignment: 'top',
              ref: "leftBottomEdge",
              onPlaceBet: _ctx.onPlaceBet
            }, {
              custombutton: _withCtx(({ onClick, onRelease }) => [
                _createElementVNode("img", {
                  src: _imports_2,
                  onMousedown: ($event: any) => (onClick($event)),
                  onMouseup: ($event: any) => (onRelease()),
                  onContextmenu: _cache[13] || (_cache[13] = (e) => e.preventDefault())
                }, null, 40, _hoisted_21)
              ]),
              _: 1
            }, 8, ["amount", "confirmed-bet", "f-temp-bet", "ratio", "show-animation", "onPlaceBet"])
          ]),
          _createElementVNode("div", _hoisted_22, [
            _createVNode(_component_bet_box, {
              class: "c3_2_1_1 top-left-edge",
              label: '3连1',
              "bet-type": '3连1',
              amount: _ctx.temp11,
              "confirmed-bet": _ctx.f11,
              "f-temp-bet": _ctx.ftemp11,
              color: 3,
              ratio: 1.9,
              "show-animation": _ctx.showResult('f11'),
              overlapcall: 1,
              onOnOverlaped: _ctx.hadleEdgeAlpaha,
              onPlaceBet: _ctx.onPlaceBet
            }, {
              custombutton: _withCtx(({ onClick, onRelease }) => [
                _createElementVNode("img", {
                  src: _imports_3,
                  onMousedown: ($event: any) => (onClick($event)),
                  onMouseup: ($event: any) => (onRelease()),
                  onContextmenu: _cache[14] || (_cache[14] = (e) => e.preventDefault())
                }, null, 40, _hoisted_23)
              ]),
              _: 1
            }, 8, ["amount", "confirmed-bet", "f-temp-bet", "ratio", "show-animation", "onOnOverlaped", "onPlaceBet"]),
            _createVNode(_component_bet_box, {
              class: "c3_2_1_2 fill",
              label: '单',
              "bet-type": '单',
              amount: _ctx.temp18,
              "confirmed-bet": _ctx.f18,
              "f-temp-bet": _ctx.ftemp18,
              color: 4,
              ratio: 0.95,
              "show-animation": _ctx.showResult('f18'),
              onPlaceBet: _ctx.onPlaceBet
            }, {
              custombutton: _withCtx(({ onClick, onRelease }) => [
                _createElementVNode("img", {
                  src: _imports_0,
                  onMousedown: ($event: any) => (onClick($event)),
                  onMouseup: ($event: any) => (onRelease()),
                  onContextmenu: _cache[15] || (_cache[15] = (e) => e.preventDefault())
                }, null, 40, _hoisted_24)
              ]),
              _: 1
            }, 8, ["amount", "confirmed-bet", "f-temp-bet", "ratio", "show-animation", "onPlaceBet"]),
            _createVNode(_component_bet_box, {
              class: "c3_2_1_1 bottom-left-edge",
              label: '1番',
              "bet-type": '1番',
              amount: _ctx.temp1,
              "confirmed-bet": _ctx.f1,
              "f-temp-bet": _ctx.ftemp1,
              color: 1,
              ratio: 2.85,
              "show-animation": _ctx.showResult('f1'),
              overlapcall: 2,
              onOnOverlaped: _ctx.hadleEdgeAlpaha,
              onPlaceBet: _ctx.onPlaceBet
            }, {
              custombutton: _withCtx(({ onClick, onRelease }) => [
                _createElementVNode("img", {
                  src: _imports_4,
                  onMousedown: ($event: any) => (onClick($event)),
                  onMouseup: ($event: any) => (onRelease()),
                  onContextmenu: _cache[16] || (_cache[16] = (e) => e.preventDefault())
                }, null, 40, _hoisted_25)
              ]),
              _: 1
            }, 8, ["amount", "confirmed-bet", "f-temp-bet", "ratio", "show-animation", "onOnOverlaped", "onPlaceBet"])
          ])
        ]),
        _createElementVNode("div", null, [
          _createElementVNode("div", _hoisted_26, [
            _createVNode(_component_bet_box, {
              class: "c3_2_1_1 top-right-edge",
              label: '3番',
              "bet-type": '3番',
              amount: _ctx.temp3,
              "confirmed-bet": _ctx.f3,
              "f-temp-bet": _ctx.ftemp3,
              color: 1,
              ratio: 2.85,
              "show-animation": _ctx.showResult('f3'),
              ref: "topRightEdge",
              onPlaceBet: _ctx.onPlaceBet
            }, {
              custombutton: _withCtx(({ onClick, onRelease }) => [
                _createElementVNode("img", {
                  src: _imports_5,
                  onMousedown: ($event: any) => (onClick($event)),
                  onMouseup: ($event: any) => (onRelease()),
                  onContextmenu: _cache[17] || (_cache[17] = (e) => e.preventDefault())
                }, null, 40, _hoisted_27)
              ]),
              _: 1
            }, 8, ["amount", "confirmed-bet", "f-temp-bet", "ratio", "show-animation", "onPlaceBet"]),
            _createVNode(_component_bet_box, {
              class: "c3_2_1_2 fill",
              label: '双',
              "bet-type": '双',
              amount: _ctx.temp21,
              "confirmed-bet": _ctx.f21,
              "f-temp-bet": _ctx.ftemp21,
              color: 2,
              ratio: 0.95,
              "show-animation": _ctx.showResult('f21'),
              onPlaceBet: _ctx.onPlaceBet
            }, {
              custombutton: _withCtx(({ onClick, onRelease }) => [
                _createElementVNode("img", {
                  src: _imports_0,
                  onMousedown: ($event: any) => (onClick($event)),
                  onMouseup: ($event: any) => (onRelease()),
                  onContextmenu: _cache[18] || (_cache[18] = (e) => e.preventDefault())
                }, null, 40, _hoisted_28)
              ]),
              _: 1
            }, 8, ["amount", "confirmed-bet", "f-temp-bet", "ratio", "show-animation", "onPlaceBet"]),
            _createVNode(_component_bet_box, {
              class: "c3_2_1_1 bottom-right-edge",
              label: '1连3',
              "bet-type": '1连3',
              amount: _ctx.temp6,
              "confirmed-bet": _ctx.f6,
              "f-temp-bet": _ctx.ftemp6,
              color: 3,
              ratio: 1.9,
              "show-animation": _ctx.showResult('f6'),
              ref: "bottomRightEdge",
              onPlaceBet: _ctx.onPlaceBet
            }, {
              custombutton: _withCtx(({ onClick, onRelease }) => [
                _createElementVNode("img", {
                  src: _imports_6,
                  onMousedown: ($event: any) => (onClick($event)),
                  onMouseup: ($event: any) => (onRelease()),
                  onContextmenu: _cache[19] || (_cache[19] = (e) => e.preventDefault())
                }, null, 40, _hoisted_29)
              ]),
              _: 1
            }, 8, ["amount", "confirmed-bet", "f-temp-bet", "ratio", "show-animation", "onPlaceBet"])
          ]),
          _createElementVNode("div", _hoisted_30, [
            _createVNode(_component_bet_box, {
              class: "c3_2_1_1 right-top-edge",
              label: '2连4',
              "bet-type": '2连4',
              amount: _ctx.temp10,
              "confirmed-bet": _ctx.f10,
              "f-temp-bet": _ctx.ftemp10,
              color: 3,
              ratio: 1.9,
              "show-animation": _ctx.showResult('f10'),
              alignment: 'bottom',
              overlapcall: 3,
              onOnOverlaped: _ctx.hadleEdgeAlpaha,
              onPlaceBet: _ctx.onPlaceBet
            }, {
              custombutton: _withCtx(({ onClick, onRelease }) => [
                _createElementVNode("img", {
                  src: _imports_7,
                  onMousedown: ($event: any) => (onClick($event)),
                  onMouseup: ($event: any) => (onRelease()),
                  onContextmenu: _cache[20] || (_cache[20] = (e) => e.preventDefault())
                }, null, 40, _hoisted_31)
              ]),
              _: 1
            }, 8, ["amount", "confirmed-bet", "f-temp-bet", "ratio", "show-animation", "onOnOverlaped", "onPlaceBet"]),
            _createVNode(_component_bet_box, {
              class: "c3_2_1_1 right-bottom-edge",
              label: '2番',
              "bet-type": '2番',
              amount: _ctx.temp2,
              "confirmed-bet": _ctx.f2,
              "f-temp-bet": _ctx.ftemp2,
              color: 1,
              ratio: 2.85,
              "show-animation": _ctx.showResult('f2'),
              alignment: 'top',
              overlapcall: 4,
              onOnOverlaped: _ctx.hadleEdgeAlpaha,
              onPlaceBet: _ctx.onPlaceBet
            }, {
              custombutton: _withCtx(({ onClick, onRelease }) => [
                _createElementVNode("img", {
                  src: _imports_8,
                  onMousedown: ($event: any) => (onClick($event)),
                  onMouseup: ($event: any) => (onRelease()),
                  onContextmenu: _cache[21] || (_cache[21] = (e) => e.preventDefault())
                }, null, 40, _hoisted_32)
              ]),
              _: 1
            }, 8, ["amount", "confirmed-bet", "f-temp-bet", "ratio", "show-animation", "onOnOverlaped", "onPlaceBet"])
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_33, [
        _createVNode(_component_bet_box, {
          class: "c3_1_25 no-margin",
          label: '1连4',
          "bet-type": '1连4',
          amount: _ctx.temp7,
          "confirmed-bet": _ctx.f7,
          "f-temp-bet": _ctx.ftemp7,
          color: 3,
          ratio: 1.9,
          "show-animation": _ctx.showResult('f7'),
          onPlaceBet: _ctx.onPlaceBet
        }, {
          custombutton: _withCtx(({ onClick, onRelease }) => [
            _createElementVNode("img", {
              src: _imports_0,
              onMousedown: ($event: any) => (onClick($event)),
              onMouseup: ($event: any) => (onRelease()),
              onContextmenu: _cache[22] || (_cache[22] = (e) => e.preventDefault())
            }, null, 40, _hoisted_34)
          ]),
          _: 1
        }, 8, ["amount", "confirmed-bet", "f-temp-bet", "ratio", "show-animation", "onPlaceBet"]),
        _createVNode(_component_bet_box, {
          class: "c3_1_fillW",
          label: '1正',
          "bet-type": '1正',
          amount: _ctx.temp23,
          "confirmed-bet": _ctx.f23,
          "f-temp-bet": _ctx.ftemp23,
          color: 4,
          ratio: 0.95,
          "show-animation": _ctx.showResult('f23'),
          onPlaceBet: _ctx.onPlaceBet
        }, {
          custombutton: _withCtx(({ onClick, onRelease }) => [
            _createElementVNode("img", {
              src: _imports_0,
              onMousedown: ($event: any) => (onClick($event)),
              onMouseup: ($event: any) => (onRelease()),
              onContextmenu: _cache[23] || (_cache[23] = (e) => e.preventDefault())
            }, null, 40, _hoisted_35)
          ]),
          _: 1
        }, 8, ["amount", "confirmed-bet", "f-temp-bet", "ratio", "show-animation", "onPlaceBet"]),
        _createVNode(_component_bet_box, {
          class: "c3_1_25",
          label: '1连2',
          "bet-type": '1连2',
          amount: _ctx.temp5,
          "confirmed-bet": _ctx.f5,
          "f-temp-bet": _ctx.ftemp5,
          color: 3,
          ratio: 1.9,
          "show-animation": _ctx.showResult('f5'),
          onPlaceBet: _ctx.onPlaceBet
        }, {
          custombutton: _withCtx(({ onClick, onRelease }) => [
            _createElementVNode("img", {
              src: _imports_0,
              onMousedown: ($event: any) => (onClick($event)),
              onMouseup: ($event: any) => (onRelease()),
              onContextmenu: _cache[24] || (_cache[24] = (e) => e.preventDefault())
            }, null, 40, _hoisted_36)
          ]),
          _: 1
        }, 8, ["amount", "confirmed-bet", "f-temp-bet", "ratio", "show-animation", "onPlaceBet"])
      ])
    ]),
    _createElementVNode("div", _hoisted_37, [
      _createVNode(_component_bet_box, {
        class: "r20",
        label: '23角',
        "bet-type": '23角',
        amount: _ctx.temp20,
        "confirmed-bet": _ctx.f20,
        "f-temp-bet": _ctx.ftemp20,
        color: 2,
        ratio: 0.95,
        "show-animation": _ctx.showResult('f20'),
        onPlaceBet: _ctx.onPlaceBet
      }, {
        custombutton: _withCtx(({ onClick, onRelease }) => [
          _createElementVNode("img", {
            src: _imports_0,
            onMousedown: ($event: any) => (onClick($event)),
            onMouseup: ($event: any) => (onRelease()),
            onContextmenu: _cache[25] || (_cache[25] = (e) => e.preventDefault())
          }, null, 40, _hoisted_38)
        ]),
        _: 1
      }, 8, ["amount", "confirmed-bet", "f-temp-bet", "ratio", "show-animation", "onPlaceBet"]),
      _createVNode(_component_bet_box, {
        class: "r20",
        label: '2连3',
        "bet-type": '2连3',
        amount: _ctx.temp9,
        "confirmed-bet": _ctx.f9,
        "f-temp-bet": _ctx.ftemp9,
        color: 3,
        ratio: 1.9,
        "show-animation": _ctx.showResult('f9'),
        onPlaceBet: _ctx.onPlaceBet
      }, {
        custombutton: _withCtx(({ onClick, onRelease }) => [
          _createElementVNode("img", {
            src: _imports_0,
            onMousedown: ($event: any) => (onClick($event)),
            onMouseup: ($event: any) => (onRelease()),
            onContextmenu: _cache[26] || (_cache[26] = (e) => e.preventDefault())
          }, null, 40, _hoisted_39)
        ]),
        _: 1
      }, 8, ["amount", "confirmed-bet", "f-temp-bet", "ratio", "show-animation", "onPlaceBet"]),
      _createVNode(_component_bet_box, {
        class: "fillH",
        label: '2正',
        "bet-type": '2正',
        amount: _ctx.temp24,
        "confirmed-bet": _ctx.f24,
        "f-temp-bet": _ctx.ftemp24,
        color: 4,
        ratio: 0.95,
        "show-animation": _ctx.showResult('f24'),
        onPlaceBet: _ctx.onPlaceBet
      }, {
        custombutton: _withCtx(({ onClick, onRelease }) => [
          _createElementVNode("img", {
            src: _imports_0,
            onMousedown: ($event: any) => (onClick($event)),
            onMouseup: ($event: any) => (onRelease()),
            onContextmenu: _cache[27] || (_cache[27] = (e) => e.preventDefault())
          }, null, 40, _hoisted_40)
        ]),
        _: 1
      }, 8, ["amount", "confirmed-bet", "f-temp-bet", "ratio", "show-animation", "onPlaceBet"]),
      _createVNode(_component_bet_box, {
        class: "r20",
        label: '2连1',
        "bet-type": '2连1',
        amount: _ctx.temp8,
        "confirmed-bet": _ctx.f8,
        "f-temp-bet": _ctx.ftemp8,
        color: 3,
        ratio: 1.9,
        "show-animation": _ctx.showResult('f8'),
        onPlaceBet: _ctx.onPlaceBet
      }, {
        custombutton: _withCtx(({ onClick, onRelease }) => [
          _createElementVNode("img", {
            src: _imports_0,
            onMousedown: ($event: any) => (onClick($event)),
            onMouseup: ($event: any) => (onRelease()),
            onContextmenu: _cache[28] || (_cache[28] = (e) => e.preventDefault())
          }, null, 40, _hoisted_41)
        ]),
        _: 1
      }, 8, ["amount", "confirmed-bet", "f-temp-bet", "ratio", "show-animation", "onPlaceBet"]),
      _createVNode(_component_bet_box, {
        class: "r20 no-margin",
        label: '12角',
        "bet-type": '12角',
        amount: _ctx.temp17,
        "confirmed-bet": _ctx.f17,
        "f-temp-bet": _ctx.ftemp17,
        color: 2,
        ratio: 0.95,
        "show-animation": _ctx.showResult('f17'),
        onPlaceBet: _ctx.onPlaceBet
      }, {
        custombutton: _withCtx(({ onClick, onRelease }) => [
          _createElementVNode("img", {
            src: _imports_0,
            onMousedown: ($event: any) => (onClick($event)),
            onMouseup: ($event: any) => (onRelease()),
            onContextmenu: _cache[29] || (_cache[29] = (e) => e.preventDefault())
          }, null, 40, _hoisted_42)
        ]),
        _: 1
      }, 8, ["amount", "confirmed-bet", "f-temp-bet", "ratio", "show-animation", "onPlaceBet"])
    ]),
    _createElementVNode("div", _hoisted_43, [
      _createVNode(_component_bet_box, {
        class: "r18",
        label: '1通23',
        "bet-type": '1通23',
        amount: _ctx.temp31,
        "confirmed-bet": _ctx.f31,
        "f-temp-bet": _ctx.ftemp31,
        color: 1,
        ratio: 0.47,
        "show-animation": _ctx.showResult('f31'),
        onPlaceBet: _ctx.onPlaceBet
      }, {
        custombutton: _withCtx(({ onClick, onRelease }) => [
          _createElementVNode("img", {
            src: _imports_0,
            onMousedown: ($event: any) => (onClick($event)),
            onMouseup: ($event: any) => (onRelease()),
            onContextmenu: _cache[30] || (_cache[30] = (e) => e.preventDefault())
          }, null, 40, _hoisted_44)
        ]),
        _: 1
      }, 8, ["amount", "confirmed-bet", "f-temp-bet", "ratio", "show-animation", "onPlaceBet"]),
      _createVNode(_component_bet_box, {
        class: "r18",
        label: '1通24',
        "bet-type": '1通24',
        amount: _ctx.temp32,
        "confirmed-bet": _ctx.f32,
        "f-temp-bet": _ctx.ftemp32,
        color: 1,
        ratio: 0.47,
        "show-animation": _ctx.showResult('f32'),
        onPlaceBet: _ctx.onPlaceBet
      }, {
        custombutton: _withCtx(({ onClick, onRelease }) => [
          _createElementVNode("img", {
            src: _imports_0,
            onMousedown: ($event: any) => (onClick($event)),
            onMouseup: ($event: any) => (onRelease()),
            onContextmenu: _cache[31] || (_cache[31] = (e) => e.preventDefault())
          }, null, 40, _hoisted_45)
        ]),
        _: 1
      }, 8, ["amount", "confirmed-bet", "f-temp-bet", "ratio", "show-animation", "onPlaceBet"]),
      _createVNode(_component_bet_box, {
        class: "r18",
        label: '1通34',
        "bet-type": '1通34',
        amount: _ctx.temp33,
        "confirmed-bet": _ctx.f33,
        "f-temp-bet": _ctx.ftemp33,
        color: 1,
        ratio: 0.47,
        "show-animation": _ctx.showResult('f33'),
        onPlaceBet: _ctx.onPlaceBet
      }, {
        custombutton: _withCtx(({ onClick, onRelease }) => [
          _createElementVNode("img", {
            src: _imports_0,
            onMousedown: ($event: any) => (onClick($event)),
            onMouseup: ($event: any) => (onRelease()),
            onContextmenu: _cache[32] || (_cache[32] = (e) => e.preventDefault())
          }, null, 40, _hoisted_46)
        ]),
        _: 1
      }, 8, ["amount", "confirmed-bet", "f-temp-bet", "ratio", "show-animation", "onPlaceBet"]),
      _createVNode(_component_bet_box, {
        class: "r18",
        label: '3通12',
        "bet-type": '3通12',
        amount: _ctx.temp37,
        "confirmed-bet": _ctx.f37,
        "f-temp-bet": _ctx.ftemp37,
        color: 1,
        ratio: 0.47,
        "show-animation": _ctx.showResult('f37'),
        onPlaceBet: _ctx.onPlaceBet
      }, {
        custombutton: _withCtx(({ onClick, onRelease }) => [
          _createElementVNode("img", {
            src: _imports_0,
            onMousedown: ($event: any) => (onClick($event)),
            onMouseup: ($event: any) => (onRelease()),
            onContextmenu: _cache[33] || (_cache[33] = (e) => e.preventDefault())
          }, null, 40, _hoisted_47)
        ]),
        _: 1
      }, 8, ["amount", "confirmed-bet", "f-temp-bet", "ratio", "show-animation", "onPlaceBet"]),
      _createVNode(_component_bet_box, {
        class: "r18",
        label: '3通14',
        "bet-type": '3通14',
        amount: _ctx.temp38,
        "confirmed-bet": _ctx.f38,
        "f-temp-bet": _ctx.ftemp38,
        color: 1,
        ratio: 0.47,
        "show-animation": _ctx.showResult('f38'),
        onPlaceBet: _ctx.onPlaceBet
      }, {
        custombutton: _withCtx(({ onClick, onRelease }) => [
          _createElementVNode("img", {
            src: _imports_0,
            onMousedown: ($event: any) => (onClick($event)),
            onMouseup: ($event: any) => (onRelease()),
            onContextmenu: _cache[34] || (_cache[34] = (e) => e.preventDefault())
          }, null, 40, _hoisted_48)
        ]),
        _: 1
      }, 8, ["amount", "confirmed-bet", "f-temp-bet", "ratio", "show-animation", "onPlaceBet"]),
      _createVNode(_component_bet_box, {
        class: "fillH no-margin",
        label: '3通24',
        "bet-type": '3通24',
        amount: _ctx.temp39,
        "confirmed-bet": _ctx.f39,
        "f-temp-bet": _ctx.ftemp39,
        color: 1,
        ratio: 0.47,
        "show-animation": _ctx.showResult('f39'),
        onPlaceBet: _ctx.onPlaceBet
      }, {
        custombutton: _withCtx(({ onClick, onRelease }) => [
          _createElementVNode("img", {
            src: _imports_0,
            onMousedown: ($event: any) => (onClick($event)),
            onMouseup: ($event: any) => (onRelease()),
            onContextmenu: _cache[35] || (_cache[35] = (e) => e.preventDefault())
          }, null, 40, _hoisted_49)
        ]),
        _: 1
      }, 8, ["amount", "confirmed-bet", "f-temp-bet", "ratio", "show-animation", "onPlaceBet"])
    ]),
    _createElementVNode("div", _hoisted_50, [
      _createVNode(_component_bet_box, {
        class: "r18",
        label: '2通13',
        "bet-type": '2通13',
        amount: _ctx.temp34,
        "confirmed-bet": _ctx.f34,
        "f-temp-bet": _ctx.ftemp34,
        color: 1,
        ratio: 0.47,
        "show-animation": _ctx.showResult('f34'),
        onPlaceBet: _ctx.onPlaceBet
      }, {
        custombutton: _withCtx(({ onClick, onRelease }) => [
          _createElementVNode("img", {
            src: _imports_0,
            onMousedown: ($event: any) => (onClick($event)),
            onMouseup: ($event: any) => (onRelease()),
            onContextmenu: _cache[36] || (_cache[36] = (e) => e.preventDefault())
          }, null, 40, _hoisted_51)
        ]),
        _: 1
      }, 8, ["amount", "confirmed-bet", "f-temp-bet", "ratio", "show-animation", "onPlaceBet"]),
      _createVNode(_component_bet_box, {
        class: "r18",
        label: '2通14',
        "bet-type": '2通14',
        amount: _ctx.temp35,
        "confirmed-bet": _ctx.f35,
        "f-temp-bet": _ctx.ftemp35,
        color: 1,
        ratio: 0.47,
        "show-animation": _ctx.showResult('f35'),
        onPlaceBet: _ctx.onPlaceBet
      }, {
        custombutton: _withCtx(({ onClick, onRelease }) => [
          _createElementVNode("img", {
            src: _imports_0,
            onMousedown: ($event: any) => (onClick($event)),
            onMouseup: ($event: any) => (onRelease()),
            onContextmenu: _cache[37] || (_cache[37] = (e) => e.preventDefault())
          }, null, 40, _hoisted_52)
        ]),
        _: 1
      }, 8, ["amount", "confirmed-bet", "f-temp-bet", "ratio", "show-animation", "onPlaceBet"]),
      _createVNode(_component_bet_box, {
        class: "r18",
        label: '2通34',
        "bet-type": '2通34',
        amount: _ctx.temp36,
        "confirmed-bet": _ctx.f36,
        "f-temp-bet": _ctx.ftemp36,
        color: 1,
        ratio: 0.47,
        "show-animation": _ctx.showResult('f36'),
        onPlaceBet: _ctx.onPlaceBet
      }, {
        custombutton: _withCtx(({ onClick, onRelease }) => [
          _createElementVNode("img", {
            src: _imports_0,
            onMousedown: ($event: any) => (onClick($event)),
            onMouseup: ($event: any) => (onRelease()),
            onContextmenu: _cache[38] || (_cache[38] = (e) => e.preventDefault())
          }, null, 40, _hoisted_53)
        ]),
        _: 1
      }, 8, ["amount", "confirmed-bet", "f-temp-bet", "ratio", "show-animation", "onPlaceBet"]),
      _createVNode(_component_bet_box, {
        class: "r18",
        label: '4通12',
        "bet-type": '4通12',
        amount: _ctx.temp40,
        "confirmed-bet": _ctx.f40,
        "f-temp-bet": _ctx.ftemp40,
        color: 1,
        ratio: 0.47,
        "show-animation": _ctx.showResult('f40'),
        onPlaceBet: _ctx.onPlaceBet
      }, {
        custombutton: _withCtx(({ onClick, onRelease }) => [
          _createElementVNode("img", {
            src: _imports_0,
            onMousedown: ($event: any) => (onClick($event)),
            onMouseup: ($event: any) => (onRelease()),
            onContextmenu: _cache[39] || (_cache[39] = (e) => e.preventDefault())
          }, null, 40, _hoisted_54)
        ]),
        _: 1
      }, 8, ["amount", "confirmed-bet", "f-temp-bet", "ratio", "show-animation", "onPlaceBet"]),
      _createVNode(_component_bet_box, {
        class: "r18",
        label: '4通13',
        "bet-type": '4通13',
        amount: _ctx.temp41,
        "confirmed-bet": _ctx.f41,
        "f-temp-bet": _ctx.ftemp41,
        color: 1,
        ratio: 0.47,
        "show-animation": _ctx.showResult('f41'),
        onPlaceBet: _ctx.onPlaceBet
      }, {
        custombutton: _withCtx(({ onClick, onRelease }) => [
          _createElementVNode("img", {
            src: _imports_0,
            onMousedown: ($event: any) => (onClick($event)),
            onMouseup: ($event: any) => (onRelease()),
            onContextmenu: _cache[40] || (_cache[40] = (e) => e.preventDefault())
          }, null, 40, _hoisted_55)
        ]),
        _: 1
      }, 8, ["amount", "confirmed-bet", "f-temp-bet", "ratio", "show-animation", "onPlaceBet"]),
      _createVNode(_component_bet_box, {
        class: "fillH no-margin",
        label: '4通23',
        "bet-type": '4通23',
        amount: _ctx.temp42,
        "confirmed-bet": _ctx.f42,
        "f-temp-bet": _ctx.ftemp42,
        color: 1,
        ratio: 0.47,
        "show-animation": _ctx.showResult('f42'),
        onPlaceBet: _ctx.onPlaceBet
      }, {
        custombutton: _withCtx(({ onClick, onRelease }) => [
          _createElementVNode("img", {
            src: _imports_0,
            onMousedown: ($event: any) => (onClick($event)),
            onMouseup: ($event: any) => (onRelease()),
            onContextmenu: _cache[41] || (_cache[41] = (e) => e.preventDefault())
          }, null, 40, _hoisted_56)
        ]),
        _: 1
      }, 8, ["amount", "confirmed-bet", "f-temp-bet", "ratio", "show-animation", "onPlaceBet"])
    ])
  ]))
}