
import {
    Emitter,
    PropType,
    SmallToast,
    TableLimit,
    computed,
    defineComponent,
    inject,
    onBeforeMount,
    onBeforeUnmount,
    onMounted,
    ref,
    watch
} from 'vue'
import { Fan2BetType, toFantan } from '@/utils/fan2'

import BetBox from '@/components/bet/bet-box.vue'
import audioPlayer, { SOUNDKEY } from '@/utils/sounds'
import { Desk } from '@/types/Desk'
import { ACT, EventBuss, OtherApiParams } from '@/types/global'
import { useI18n } from 'vue-i18n'
import { useStore } from '@/store'
import { fetch } from '@/api'
import { AppActions, AppMutation } from '@/store/types'

export default defineComponent({
    emits: ['bet-change'],
    props: {
        chipValue: {
            type: Number as PropType<number>,
            required: false,
            default: () => 0
        },
        isSwitchTableIsVisible: {
            type: Boolean as PropType<boolean>,
            required: false,
            default: () => false
        },
        desk: {
            type: Number as PropType<number>,
            required: false,
            default: () => -1
        },
        result: {
            type: Array as PropType<string[]>,
            required: false,
            default: () => []
        },
        allowBet: {
            type: Boolean as PropType<boolean>,
            required: false,
            default: () => false
        },
        coin: {
            type: Number as PropType<number>,
            required: false,
            default: () => 0
        },
        deskData: {
            type: Object as PropType<Desk>
        }
    },
    components: {
        'bet-box': BetBox
    },
    setup(props, { emit }) {
        const emitter = inject('emitter') as Emitter
        const store = useStore()
        const { t } = useI18n()
        const leftTopEdge = ref()
        const leftBottomEdge = ref()
        const topRightEdge = ref()
        const bottomRightEdge = ref()

        const temp1 = ref(0)
        const temp2 = ref(0)
        const temp3 = ref(0)
        const temp4 = ref(0)
        const temp5 = ref(0)
        const temp6 = ref(0)
        const temp7 = ref(0)
        const temp8 = ref(0)
        const temp9 = ref(0)
        const temp10 = ref(0)
        const temp11 = ref(0)
        const temp12 = ref(0)
        const temp13 = ref(0)
        const temp14 = ref(0)
        const temp15 = ref(0)
        const temp16 = ref(0)
        const temp17 = ref(0)
        const temp18 = ref(0)
        const temp19 = ref(0)
        const temp20 = ref(0)
        const temp21 = ref(0)
        const temp22 = ref(0)
        const temp23 = ref(0)
        const temp24 = ref(0)
        const temp25 = ref(0)
        const temp26 = ref(0)
        const temp27 = ref(0)
        const temp28 = ref(0)
        const temp29 = ref(0)
        const temp30 = ref(0)
        const temp31 = ref(0)
        const temp32 = ref(0)
        const temp33 = ref(0)
        const temp34 = ref(0)
        const temp35 = ref(0)
        const temp36 = ref(0)
        const temp37 = ref(0)
        const temp38 = ref(0)
        const temp39 = ref(0)
        const temp40 = ref(0)
        const temp41 = ref(0)
        const temp42 = ref(0)

        const ftemp1 = ref(0)
        const ftemp2 = ref(0)
        const ftemp3 = ref(0)
        const ftemp4 = ref(0)
        const ftemp5 = ref(0)
        const ftemp6 = ref(0)
        const ftemp7 = ref(0)
        const ftemp8 = ref(0)
        const ftemp9 = ref(0)
        const ftemp10 = ref(0)
        const ftemp11 = ref(0)
        const ftemp12 = ref(0)
        const ftemp13 = ref(0)
        const ftemp14 = ref(0)
        const ftemp15 = ref(0)
        const ftemp16 = ref(0)
        const ftemp17 = ref(0)
        const ftemp18 = ref(0)
        const ftemp19 = ref(0)
        const ftemp20 = ref(0)
        const ftemp21 = ref(0)
        const ftemp22 = ref(0)
        const ftemp23 = ref(0)
        const ftemp24 = ref(0)
        const ftemp25 = ref(0)
        const ftemp26 = ref(0)
        const ftemp27 = ref(0)
        const ftemp28 = ref(0)
        const ftemp29 = ref(0)
        const ftemp30 = ref(0)
        const ftemp31 = ref(0)
        const ftemp32 = ref(0)
        const ftemp33 = ref(0)
        const ftemp34 = ref(0)
        const ftemp35 = ref(0)
        const ftemp36 = ref(0)
        const ftemp37 = ref(0)
        const ftemp38 = ref(0)
        const ftemp39 = ref(0)
        const ftemp40 = ref(0)
        const ftemp41 = ref(0)
        const ftemp42 = ref(0)

        const f1 = ref(0)
        const f2 = ref(0)
        const f3 = ref(0)
        const f4 = ref(0)
        const f5 = ref(0)
        const f6 = ref(0)
        const f7 = ref(0)
        const f8 = ref(0)
        const f9 = ref(0)
        const f10 = ref(0)
        const f11 = ref(0)
        const f12 = ref(0)
        const f13 = ref(0)
        const f14 = ref(0)
        const f15 = ref(0)
        const f16 = ref(0)
        const f17 = ref(0)
        const f18 = ref(0)
        const f19 = ref(0)
        const f20 = ref(0)
        const f21 = ref(0)
        const f22 = ref(0)
        const f23 = ref(0)
        const f24 = ref(0)
        const f25 = ref(0)
        const f26 = ref(0)
        const f27 = ref(0)
        const f28 = ref(0)
        const f29 = ref(0)
        const f30 = ref(0)
        const f31 = ref(0)
        const f32 = ref(0)
        const f33 = ref(0)
        const f34 = ref(0)
        const f35 = ref(0)
        const f36 = ref(0)
        const f37 = ref(0)
        const f38 = ref(0)
        const f39 = ref(0)
        const f40 = ref(0)
        const f41 = ref(0)
        const f42 = ref(0)

        const betvalues = [
            temp1,
            temp2,
            temp3,
            temp4,
            temp5,
            temp6,
            temp7,
            temp8,
            temp9,
            temp10,
            temp11,
            temp12,
            temp13,
            temp14,
            temp15,
            temp16,
            temp17,
            temp18,
            temp19,
            temp20,
            temp21,
            temp22,
            temp23,
            temp24,
            temp25,
            temp26,
            temp27,
            temp28,
            temp29,
            temp30,
            temp31,
            temp32,
            temp33,
            temp34,
            temp35,
            temp36,
            temp37,
            temp38,
            temp39,
            temp40,
            temp41,
            temp42
        ]

        const ftempValues = [
            ftemp1,
            ftemp2,
            ftemp3,
            ftemp4,
            ftemp5,
            ftemp6,
            ftemp7,
            ftemp8,
            ftemp9,
            ftemp10,
            ftemp11,
            ftemp12,
            ftemp13,
            ftemp14,
            ftemp15,
            ftemp16,
            ftemp17,
            ftemp18,
            ftemp19,
            ftemp20,
            ftemp21,
            ftemp22,
            ftemp23,
            ftemp24,
            ftemp25,
            ftemp26,
            ftemp27,
            ftemp28,
            ftemp29,
            ftemp30,
            ftemp31,
            ftemp32,
            ftemp33,
            ftemp34,
            ftemp35,
            ftemp36,
            ftemp37,
            ftemp38,
            ftemp39,
            ftemp40,
            ftemp41,
            ftemp42
        ]

        onBeforeMount(() => {
            //
        })

        onBeforeUnmount(() => {
            //
        })

        onMounted(() => {
            // console.log(props.deskData)
        })

        const hadleEdgeAlpaha = (type: number) => {
            if (type === 1 && leftTopEdge.value) {
                leftTopEdge.value.forceCliked()
            } else if (type === 2 && leftBottomEdge.value) {
                leftBottomEdge.value.forceCliked()
            } else if (type === 3 && topRightEdge.value) {
                topRightEdge.value.forceCliked()
            } else if (type === 4 && bottomRightEdge.value) {
                bottomRightEdge.value.forceCliked()
            }
        }

        const onPlaceBet = (betType: Fan2BetType) => {
            if (props.chipValue > 0 && !props.isSwitchTableIsVisible) {
                if (props.allowBet) {
                    const amt = sumOffTempBet(props.chipValue)

                    if (amt > props.coin) {
                        const _m: SmallToast = {
                            id: props.desk,
                            message: t('balanceshort'),
                            color: 'red'
                        }
                        emitter.emit(EventBuss.SMALL_TOAST, _m)
                    } else {
                        const numType = toFantan(betType)

                        switch (numType) {
                            case 'f1': {
                                temp1.value += props.chipValue
                                break
                            }
                            case 'f2': {
                                temp2.value += props.chipValue
                                break
                            }
                            case 'f3': {
                                temp3.value += props.chipValue
                                break
                            }
                            case 'f4': {
                                temp4.value += props.chipValue
                                break
                            }
                            case 'f5': {
                                temp5.value += props.chipValue
                                break
                            }
                            case 'f6': {
                                temp6.value += props.chipValue
                                break
                            }
                            case 'f7': {
                                temp7.value += props.chipValue
                                break
                            }
                            case 'f8': {
                                temp8.value += props.chipValue
                                break
                            }
                            case 'f9': {
                                temp9.value += props.chipValue
                                break
                            }
                            case 'f10': {
                                temp10.value += props.chipValue
                                break
                            }
                            case 'f11': {
                                temp11.value += props.chipValue
                                break
                            }
                            case 'f12': {
                                temp12.value += props.chipValue
                                break
                            }
                            case 'f13': {
                                temp13.value += props.chipValue
                                break
                            }
                            case 'f14': {
                                temp14.value += props.chipValue
                                break
                            }
                            case 'f15': {
                                temp15.value += props.chipValue
                                break
                            }
                            case 'f16': {
                                temp16.value += props.chipValue
                                break
                            }
                            case 'f17': {
                                temp17.value += props.chipValue
                                break
                            }
                            case 'f18': {
                                temp18.value += props.chipValue
                                break
                            }
                            case 'f19': {
                                temp19.value += props.chipValue
                                break
                            }
                            case 'f20': {
                                temp20.value += props.chipValue
                                break
                            }
                            case 'f21': {
                                temp21.value += props.chipValue
                                break
                            }
                            case 'f22': {
                                temp22.value += props.chipValue
                                break
                            }
                            case 'f23': {
                                temp23.value += props.chipValue
                                break
                            }
                            case 'f24': {
                                temp24.value += props.chipValue
                                break
                            }
                            case 'f25': {
                                temp25.value += props.chipValue
                                break
                            }
                            case 'f26': {
                                temp26.value += props.chipValue
                                break
                            }
                            case 'f27': {
                                temp27.value += props.chipValue
                                break
                            }
                            case 'f28': {
                                temp28.value += props.chipValue
                                break
                            }
                            case 'f29': {
                                temp29.value += props.chipValue
                                break
                            }
                            case 'f30': {
                                temp30.value += props.chipValue
                                break
                            }
                            case 'f31': {
                                temp31.value += props.chipValue
                                break
                            }
                            case 'f32': {
                                temp32.value += props.chipValue
                                break
                            }
                            case 'f33': {
                                temp33.value += props.chipValue
                                break
                            }
                            case 'f34': {
                                temp34.value += props.chipValue
                                break
                            }
                            case 'f35': {
                                temp35.value += props.chipValue
                                break
                            }
                            case 'f36': {
                                temp36.value += props.chipValue
                                break
                            }
                            case 'f37': {
                                temp37.value += props.chipValue
                                break
                            }
                            case 'f38': {
                                temp38.value += props.chipValue
                                break
                            }
                            case 'f39': {
                                temp39.value += props.chipValue
                                break
                            }
                            case 'f40': {
                                temp40.value += props.chipValue
                                break
                            }
                            case 'f41': {
                                temp41.value += props.chipValue
                                break
                            }
                            case 'f42': {
                                temp42.value += props.chipValue
                                break
                            }
                        }

                        audioPlayer.Play(SOUNDKEY.BET_PLACE)
                        emit('bet-change', sumOffTempBet(0))
                    }
                } else {
                    const _m: SmallToast = {
                        id: props.desk,
                        message: '当前不能下注',
                        color: 'red'
                    }
                    emitter.emit(EventBuss.SMALL_TOAST, _m)
                }
            } else {
                const _m: SmallToast = {
                    id: props.desk,
                    message: t('nochipisselected'),
                    color: 'red'
                }
                emitter.emit(EventBuss.SMALL_TOAST, _m)
            }
        }

        const sumOffTempBet = (amount: number) => {
            let tempAmount = amount

            betvalues.forEach((x) => {
                tempAmount += x.value
            })

            return tempAmount
        }

        const showResult = (key: string) => {
            return props.result.indexOf(key) != -1
        }

        const clearBet = () => {
            betvalues.forEach((x) => {
                x.value = 0
            })
            emit('bet-change', sumOffTempBet(0))
        }

        const confirmBet = () => {
            let totalTempBet = sumOffTempBet(0)
            if (totalTempBet > 0 && props.allowBet) {
                if (totalTempBet > props.coin) {
                    emitter.emit(EventBuss.TOAST_MESSAGE, t('balanceshort'))
                } else {
                    const model: any = {
                        ...OtherApiParams,
                        act: ACT.DO_FUNTAN_BET,
                        username: user.value,
                        sessionID: token.value,
                        desk: props.desk,
                        xian: xian.value.Id || 0
                    }

                    betvalues.forEach((x, i) => {
                        if (x.value > 0) model[`f${i + 1}`] = x.value
                    })

                    store.commit(AppMutation.SHOW_LOADING)

                    store
                        .dispatch(AppActions.FETCH_DATA, model)
                        .then((response) => {
                            store.commit(AppMutation.HIDE_LOADING)

                            if (response.includes('xzok')) {
                                audioPlayer.Play(SOUNDKEY.BET_SUCCESS)
                                const _m: SmallToast = {
                                    id: props.desk,
                                    message: t('betsuccess'),
                                    color: 'green'
                                }
                                emitter.emit(EventBuss.SMALL_TOAST, _m)
                                betvalues.forEach((x, i) => {
                                    if (x.value > 0)
                                        ftempValues[i].value = x.value
                                    else ftempValues[i].value = 0
                                })
                            } else if (response.includes('xzno')) {
                                // bet failed
                                const errorArr = response.split('#')
                                errorArr.pop()

                                const _m: SmallToast = {
                                    id: props.desk,
                                    message: errorArr[1],
                                    color: 'red'
                                }

                                emitter.emit(EventBuss.SMALL_TOAST, _m)
                            }

                            // reset temp bet
                            clearBet()
                            // betvalues.forEach((x) => {
                            //     x.value = 0
                            // })
                        })
                        .catch(() => {
                            store.commit(AppMutation.HIDE_LOADING)
                            emitter.emit(
                                EventBuss.TOAST_MESSAGE,
                                t('networkerror')
                            )
                        })
                }
            }
        }

        const token = computed(() => store.getters['token'])
        const user = computed(() => store.getters['user'])
        const xian = computed((): TableLimit => store.getters['xian'])

        watch(
            () => props.deskData,
            (_v) => {
                if (_v) {
                    ftempValues.forEach((x) => {
                        x.value = 0
                    })

                    f1.value = _v.f1
                    f2.value = _v.f2
                    f3.value = _v.f3
                    f4.value = _v.f4
                    f5.value = _v.f5
                    f6.value = _v.f6
                    f7.value = _v.f7
                    f8.value = _v.f8
                    f9.value = _v.f9
                    f10.value = _v.f10
                    f11.value = _v.f11
                    f12.value = _v.f12
                    f13.value = _v.f13
                    f14.value = _v.f14
                    f15.value = _v.f15
                    f16.value = _v.f16
                    f17.value = _v.f17
                    f18.value = _v.f18
                    f19.value = _v.f19
                    f20.value = _v.f20
                    f21.value = _v.f21
                    f22.value = _v.f22
                    f23.value = _v.f23
                    f24.value = _v.f24
                    f25.value = _v.f25
                    f26.value = _v.f26
                    f27.value = _v.f27
                    f28.value = _v.f28
                    f29.value = _v.f29
                    f30.value = _v.f30
                    f31.value = _v.f31
                    f32.value = _v.f32
                    f33.value = _v.f33
                    f34.value = _v.f34
                    f35.value = _v.f35
                    f36.value = _v.f36
                    f37.value = _v.f37
                    f38.value = _v.f38
                    f39.value = _v.f39
                    f40.value = _v.f40
                    f41.value = _v.f41
                    f42.value = _v.f42
                }
            },
            { deep: true, immediate: true }
        )

        return {
            leftTopEdge,
            leftBottomEdge,
            topRightEdge,
            bottomRightEdge,
            hadleEdgeAlpaha,
            onPlaceBet,
            temp1,
            temp2,
            temp3,
            temp4,
            temp5,
            temp6,
            temp7,
            temp8,
            temp9,
            temp10,
            temp11,
            temp12,
            temp13,
            temp14,
            temp15,
            temp16,
            temp17,
            temp18,
            temp19,
            temp20,
            temp21,
            temp22,
            temp23,
            temp24,
            temp25,
            temp26,
            temp27,
            temp28,
            temp29,
            temp30,
            temp31,
            temp32,
            temp33,
            temp34,
            temp35,
            temp36,
            temp37,
            temp38,
            temp39,
            temp40,
            temp41,
            temp42,
            f1,
            f2,
            f3,
            f4,
            f5,
            f6,
            f7,
            f8,
            f9,
            f10,
            f11,
            f12,
            f13,
            f14,
            f15,
            f16,
            f17,
            f18,
            f19,
            f20,
            f21,
            f22,
            f23,
            f24,
            f25,
            f26,
            f27,
            f28,
            f29,
            f30,
            f31,
            f32,
            f33,
            f34,
            f35,
            f36,
            f37,
            f38,
            f39,
            f40,
            f41,
            f42,
            ftemp1,
            ftemp2,
            ftemp3,
            ftemp4,
            ftemp5,
            ftemp6,
            ftemp7,
            ftemp8,
            ftemp9,
            ftemp10,
            ftemp11,
            ftemp12,
            ftemp13,
            ftemp14,
            ftemp15,
            ftemp16,
            ftemp17,
            ftemp18,
            ftemp19,
            ftemp20,
            ftemp21,
            ftemp22,
            ftemp23,
            ftemp24,
            ftemp25,
            ftemp26,
            ftemp27,
            ftemp28,
            ftemp29,
            ftemp30,
            ftemp31,
            ftemp32,
            ftemp33,
            ftemp34,
            ftemp35,
            ftemp36,
            ftemp37,
            ftemp38,
            ftemp39,
            ftemp40,
            ftemp41,
            ftemp42,
            clearBet,
            showResult,
            confirmBet
        }
    }
})
