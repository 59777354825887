import { Dictionary } from '@/types/dictionary'
import { RoadMapProperties } from './datamodel/RoadMapProperties'
import { RoadMapData } from './datamodel/RoadMapData'
import { BigItem } from './datamodel/BigItem'
import { BeadItems } from './datamodel/BeadItems'
import { RoadType, TypeBead, bigColor } from './datamodel/types'
import { RoadMapItem } from './datamodel/RoadMapItem'
import { BaseRoadmapItem } from './datamodel/BaseRoadmapItem'
/**
 * Fix the Blur of Canvas
 * @param canvas
 */
const fixCanvasSize = (
    canvas: HTMLCanvasElement
): RoadMapProperties | undefined => {
    const dpi = window.devicePixelRatio || 1

    let styleHeight
    let styleWidth
    try {
        styleHeight = +getComputedStyle(canvas)
            .getPropertyValue('height')
            .slice(0, -2)
        styleWidth = +getComputedStyle(canvas)
            .getPropertyValue('width')
            .slice(0, -2)
    } catch (e) {
        console.log(e)
        return undefined
    }

    //  fix canvas blur
    canvas.setAttribute('height', `${styleHeight * dpi}`)
    canvas.setAttribute('width', `${styleWidth * dpi}`)

    const width = canvas.clientWidth * dpi
    const height = canvas.clientHeight * dpi

    canvas.width = width
    canvas.height = height

    const ctx = canvas.getContext('2d', { alpha: false })

    if (!ctx) {
        throw new Error('canvas Context Error')
    }
    return new RoadMapProperties(ctx, width, height, 0, dpi)
}

/**
 * Draw Grid Single
 * @param canvas
 */
export function drawSingleGridMap(
    canvas: HTMLCanvasElement,
    colors?: { stroke?: string; fill?: string }
): RoadMapProperties | undefined {
    const mapProp = fixCanvasSize(canvas)
    if (mapProp) {
        const { ctx, dpi, width, height } = mapProp

        if (!ctx) {
            return undefined
        }

        const border = 1 * dpi
        // border
        ctx.beginPath()
        ctx.rect(0, 0, width, height)
        if (colors && colors.fill) {
            ctx.fillStyle = colors.fill
        } else {
            ctx.fillStyle = '#ccc'
        }
        ctx.fill()
        ctx.closePath()

        // background
        ctx.beginPath()
        ctx.rect(border, border, width - border * 2, height - border * 2)

        if (colors && colors.fill) {
            ctx.fillStyle = colors.fill
        } else {
            ctx.fillStyle = '#fff'
        }
        ctx.fill()
        ctx.closePath()

        const lineWidth = 1 * dpi

        ctx.beginPath()

        if (colors && colors.stroke) {
            ctx.strokeStyle = colors.stroke
        } else {
            ctx.strokeStyle = '#999999'
        }

        ctx.lineWidth = lineWidth

        const mCWidth = height / 6

        const mColCount = parseInt(`${width / (mCWidth > 0 ? mCWidth : 0.1)}`)

        for (let i = 0; i <= mColCount; i++) {
            let pointX = i * mCWidth
            if (i === 0) {
                pointX = pointX + lineWidth / 2
            }
            ctx.moveTo(pointX, 0)
            ctx.lineTo(pointX, height)
            if (i <= 6) {
                let pointY = i * mCWidth
                if (i === 0) {
                    pointY = pointY + lineWidth / 2
                }
                ctx.moveTo(0, pointY)
                ctx.lineTo(width, pointY)
            }
        }
        ctx.stroke()
        ctx.closePath()

        return new RoadMapProperties(ctx, width, height, mCWidth, dpi)
    } else {
        return undefined
    }
}

export function parseRoadMap(strData: string): RoadMapData {
    const TypeColor = { RED: 0, BLUE: 2 }

    const result = new RoadMapData()

    const parseData = (strData: string) => {
        if (strData.length === 0) return undefined

        const strValues = strData.split('#')
        strValues.pop()

        const tieMap = new Dictionary<number>()
        const bigItems: BigItem[] = []
        const bigeyeItems: BaseRoadmapItem[] = []
        const smallItems: BaseRoadmapItem[] = []
        const cockroachItems: BaseRoadmapItem[] = []

        const assestBead = (
            type: number,
            index: number,
            value: number,
            isPair: boolean
        ) => {
            const x = Math.floor(index / 6)
            const y = index % 6

            // const val = Math.floor(value / 10)
            const bead: BeadItems = new BeadItems(x, y, type, value)
            bead.setPair(isPair)
            result.beads.push(bead)
        }

        const assestBigItemTemp = (
            index: number,
            list: BigItem[],
            mPost: Dictionary<number>
        ) => {
            let type = TypeBead.GREEN
            const item = result.beads[index]

            if (item.type === TypeBead.GREEN) {
                if (list.length > 0) {
                    const locationX = list.length - 1
                    const locationY = list[locationX].size - 1
                    const key = `${locationX}:${locationY}`
                    if (mPost.ContainsKey(key)) {
                        const count = mPost.Item(key) + 1
                        mPost.Add(key, count)
                    } else {
                        mPost.Add(key, 1)
                    }
                }
            } else if (item.type === TypeBead.RED) {
                type = TypeBead.RED
            } else if (item.type === TypeBead.BLUE) {
                type = TypeBead.BLUE
            }

            if (type !== TypeBead.GREEN) {
                if (list.length > 0) {
                    const lstItem = list.length - 1
                    if (list[lstItem].type === item.type) {
                        list[lstItem].addSize(item.value)
                    } else {
                        const toAdd = new BigItem(item.type, 0, 0, item.value)

                        toAdd.addSize(item.value)
                        list.push(toAdd)
                    }
                } else {
                    const toAdd = new BigItem(item.type, 0, 0, item.value)
                    toAdd.addSize(item.value)
                    list.push(toAdd)
                }
            }
        }

        /**
         * Assest the BigItems
         * @param paramList
         * @param resultingList
         * @param resultingMap
         */
        const assestBigItems = (
            paramList: RoadMapItem[],
            resultingList: BigItem[]
        ) => {
            const mapItem = new Dictionary<number>()
            let offsetX = 0

            for (let i = 0; i < paramList.length; i++) {
                const item = paramList[i]
                const size = item.size
                let locationY = 0
                let locationX = i + offsetX

                for (let j = 0; j < size; j++) {
                    let key = `${locationX}:${locationY}`

                    while (mapItem.ContainsKey(key)) {
                        if (locationY < 5) {
                            locationY += 1
                            const tempKey = `${locationX}:${locationY}`
                            if (mapItem.ContainsKey(tempKey)) {
                                locationX += 1
                                locationY -= 1
                            }
                        } else if (locationY >= 5) {
                            locationY = 5
                            locationX += 1
                        }

                        key = `${locationX}:${locationY}`
                        const prevItem = resultingList[resultingList.length - 1]
                        if (locationY === 0 && prevItem.type === item.type) {
                            offsetX += 1
                        }
                    }

                    mapItem.Add(key, item.type)

                    const tempItem = new BigItem(
                        item.type,
                        locationX,
                        locationY,
                        item.values[j]
                    )

                    resultingList.push(tempItem)
                }
            }
        }

        strValues.forEach((strValue, key) => {
            let value = parseInt(strValue)
            if (value < 10) value = value * 10

            let bType = 0
            result.round += 1
            const isPair = value % 10 > 0

            const rawValue = Math.floor(value / 10)

            const getBType = (rvalue: number) => {
                let _bType = 0
                if (rawValue === 1) {
                    _bType = 1
                    result.oneCount += 1
                } else if (rawValue === 2) {
                    _bType = 0
                    result.twoCount += 1
                } else if (rawValue === 3) {
                    _bType = 1
                    result.threeCount += 1
                } else if (rawValue === 4) {
                    _bType = 0
                    result.fourCount += 1
                }
                return _bType
            }

            if (rawValue !== 5) {
                bType = getBType(rawValue)
            } else {
                result.fiveCount += 1
                if (key === 0) bType = 1
                else {
                    if (rawValue === 5 && key > 0) {
                        bType = result.beads[key - 1].type
                    } else if (strValues[key - 1] === strValues[key]) {
                        bType = result.beads[key - 1].type
                    }
                }
            }

            if (bType === TypeBead.RED && rawValue !== 5) {
                result.evenCount += 1
            } else if (bType === TypeBead.BLUE && rawValue !== 5) {
                result.oddCount += 1
            }

            assestBead(bType, key, value, isPair)
            assestBigItemTemp(key, bigItems, tieMap)
        })

        assestBigItems(bigItems, result.bigs)
    }

    parseData(strData)

    // check good way

    return result
}

/**
 * Draw the roadmap into the canvas
 * @param options
 * @param arrs
 * @param roadType
 * @param offsetY
 * @param offsetX
 */
export function drawRoadMap(
    options: RoadMapProperties | undefined,
    arrs: Array<any>,
    roadType = RoadType.BEAD,
    offsetY = 0,
    offsetX = 0,
    gameType = 1,
    t = (s: string): string => {
        return ''
    },
    display = 0
): { index: number; path2D: Path2D }[] | undefined {
    if (!options) return

    const beadRefs: { index: number; path2D: Path2D }[] = []

    const { colWidth, ctx, colNum, dpi } = options

    let radius: number
    if (roadType === RoadType.BEAD) {
        radius = colWidth / 2
    } else if (roadType === RoadType.BIG) {
        radius = colWidth / 4
    } else {
        radius = colWidth / 8
    }

    // color vars
    const RED = '#FF2E2E'
    const BLUE = '#013fff'
    const GREEN = '#00A70B'
    const BROWN = '#8b5607'
    const PAIR = '#acacac'
    const TIE = '#864C1F'
    const PURPLE = '#ae01ba'
    // const TIE_STROKE = '#FFB07F'

    const count = arrs.length
    if (count === 0) {
        return
    }

    const scroll = arrs[count - 1].x - (colNum - 1)

    try {
        arrs.forEach((item, i) => {
            const { type, x, y, pair, value, tieSize } = item

            let inRange = false
            let scrollX = x

            if (i === arrs.length - 1 && options.isPre && scrollX >= scroll) {
                return
            }

            if (scrollX >= scroll) {
                inRange = true
                if (scroll > 0) {
                    scrollX = scrollX - scroll
                }

                if (scrollX > colNum - 1) {
                    return
                }
            }

            if (inRange) {
                const pointY = y * radius * 2 + offsetY
                const pointX = scrollX * radius * 2 + offsetX

                if (roadType === RoadType.BEAD) {
                    let color = RED
                    let label = ''
                    const { value } = item

                    const __v = Math.floor(value / 10)

                    if (type === TypeBead.RED) {
                        // label = '庄'
                        // label = gameType === 1 ? t('bankerabt') : t('dragonabt')
                        label = display === 0 ? '双' : `${__v}`
                        color = RED
                        if (__v === 5) {
                            label = '和'
                            color = TIE
                        }
                    } else if (type === TypeBead.BLUE) {
                        color = BLUE
                        label = display === 0 ? '单' : `${__v}`
                        if (__v === 5) {
                            label = '和'
                            color = TIE
                        }
                    }

                    if (display !== 0) {
                        if (__v === 1) {
                            color = RED
                        } else if (__v === 2) {
                            color = GREEN
                        } else if (__v === 3) {
                            color = GREEN
                        } else if (__v === 4) {
                            color = RED
                        } else if (__v === 5) {
                            color = TIE
                            label = '和'
                        }
                    }

                    const beadRef = { index: i, path2D: new Path2D() }

                    ctx.lineWidth = options.lineWidth
                    ctx.fillStyle = color
                    // ctx.strokeStyle = color
                    // ctx.strokeStyle = '#000'
                    // ctx.beginPath()
                    beadRef.path2D.arc(
                        radius + pointX,
                        radius + pointY,
                        (colWidth / 2) * 0.8,
                        0,
                        2 * Math.PI
                    )
                    // ctx.stroke()
                    ctx.fill(beadRef.path2D)
                    // ctx.stroke(beadRef.path2D)
                    // ctx.closePath()
                    beadRefs.push(beadRef)
                    if (pair) {
                        const pairPath = new Path2D()
                        pairPath.arc(
                            radius + pointX,
                            radius + pointY,
                            colWidth * 0.38 * 0.8,
                            0,
                            2 * Math.PI
                        )

                        // Set fill style for the circle
                        ctx.strokeStyle = '#FFFFFF'
                        // ctx.fillStyle = PAIR
                        // ctx.fill(pairPath)
                        ctx.stroke(pairPath)
                        ctx.closePath()
                    }

                    ctx.font = `500 ${radius}px ms-yh, Verdana, Geneva, Tahoma, sans-serif`
                    ctx.textAlign = 'center'
                    ctx.textBaseline = 'middle'

                    // Set fill style for the text
                    // ctx.fillStyle = color
                    ctx.fillStyle = '#ffffff'
                    ctx.fillText(
                        label,
                        radius + pointX,
                        pointY + radius + options.lineWidth / 2
                    )
                    ctx.closePath()
                } else if (roadType === RoadType.BIG) {
                    const __v = Math.floor(item.value / 10)
                    ctx.lineWidth = options.lineWidth * 0.8

                    let color = RED
                    if (__v === 5) {
                        // console.log('color tie')
                        color = TIE
                    } else {
                        // color = type === bigColor.RED ? RED : BLUE

                        if (__v === 1 || __v === 4) {
                            // console.log('jj')
                            color = RED
                        } else if (__v === 2 || __v === 3) {
                            color = GREEN
                        }
                    }

                    ctx.strokeStyle = color
                    ctx.fillStyle = color
                    ctx.beginPath()
                    ctx.arc(
                        radius + pointX,
                        radius + pointY,
                        (radius - ctx.lineWidth) * 0.8,
                        0,
                        2 * Math.PI
                    )
                    ctx.stroke()
                    ctx.fill()
                    ctx.closePath()

                    if (item.value % 10 > 0) {
                        const pairPath = new Path2D()
                        pairPath.arc(
                            radius + pointX,
                            radius + pointY,
                            colWidth * 0.17 * 0.8,
                            0,
                            2 * Math.PI
                        )

                        // Set fill style for the circle
                        ctx.fillStyle = PAIR
                        ctx.lineWidth = options.lineWidth * 0.8
                        ctx.strokeStyle = '#FFFFFF'
                        // ctx.stroke()

                        // ctx.fill(pairPath)
                        ctx.stroke(pairPath)

                        ctx.closePath()
                    }

                    // ctx.fillStyle = color
                    ctx.fillStyle = '#ffffff'

                    ctx.font = `500 ${radius}px ms-yh , Verdana, Geneva, Tahoma, sans-serif`
                    ctx.textAlign = 'center'
                    ctx.textBaseline = 'middle'

                    const label = __v === 5 ? '和' : `${__v}`

                    ctx.fillText(
                        label,
                        radius + pointX,
                        pointY + radius + options.lineWidth / 2
                    )
                }
            }
        })
    } catch {
        console.log('Canvas Is Too Small Can not draw the road map')
    }

    return beadRefs
}
