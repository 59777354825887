import { CallbackFunction, ref } from 'vue'

export function useCountdown(callback?: CallbackFunction) {
    const currentCount = ref<number>(-1)
    let countDownInterval: boolean | number = false

    const startCountDown = (time: number) => {
        if (typeof countDownInterval !== 'number') {
            currentCount.value = time

            countDownInterval = setInterval(() => {
                if (currentCount.value < 0) {
                    stopCountDown()
                } else {
                    currentCount.value -= 1
                }
            }, 1000)
        }
    }

    const stopCountDown = (): void => {
        if (typeof countDownInterval === 'number') {
            clearInterval(countDownInterval)
            countDownInterval = false
            currentCount.value = -1
        }

        if (callback) {
            callback()
        }
    }

    return {
        currentCount,
        startCountDown,
        stopCountDown
    }
}
