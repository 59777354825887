<template>
    <div class="video-loader-root">
        <div class="bar1"></div>
        <div class="bar2"></div>
        <div class="bar3"></div>
        <div class="bar4"></div>
        <div class="bar5"></div>
        <div class="bar6"></div>
    </div>
</template>

<style lang="scss" scoped>
.video-loader-root {
    margin: 0 auto;
    width: var(--pc60);
    height: var(--pc50);
    text-align: center;
    font-size: var(--pc10);
    position: absolute;

    // top: 50%;
    // left: 50%;
    // -webkit-transform: translateY(-50%) translateX(-50%);
    // transform: translateY(-50%) translateX(-50%);
    > div {
        height: 100%;
        width: var(--pc8);
        display: inline-block;
        float: left;
        margin-left: var(--pc2);
        -webkit-animation: delay 0.8s infinite ease-in-out;
        animation: delay 0.8s infinite ease-in-out;
    }
    .bar1 {
        background-color: #754fa0;
    }
    .bar2 {
        background-color: #09b7bf;
        -webkit-animation-delay: -0.7s;
        animation-delay: -0.7s;
    }
    .bar3 {
        background-color: #90d36b;
        -webkit-animation-delay: -0.6s;
        animation-delay: -0.6s;
    }
    .bar4 {
        background-color: #f2d40d;
        -webkit-animation-delay: -0.5s;
        animation-delay: -0.5s;
    }
    .bar5 {
        background-color: #fcb12b;
        -webkit-animation-delay: -0.4s;
        animation-delay: -0.4s;
    }
    .bar6 {
        background-color: #ed1b72;
        -webkit-animation-delay: -0.3s;
        animation-delay: -0.3s;
    }
    &::after {
        position: absolute;
        content: '加载中';
        color: var(--white);
        top: calc(100% + var(--pc5));
        left: 0;
        right: 0;
        bottom: auto;
        margin: auto;
        font-size: var(--pc15);
        text-align: center;
        white-space: nowrap;
    }
}
</style>
