
import {
    Emitter,
    PropType,
    computed,
    defineComponent,
    inject,
    onBeforeMount,
    onBeforeUnmount,
    ref
} from 'vue'
import { isClicked } from '@/utils'
import { EventBuss } from '@/types/global'

import { Fan2BetType } from '@/utils/fan2'

export default defineComponent({
    emits: ['on-overlaped', 'place-bet'],
    props: {
        label: {
            type: String as PropType<string>,
            required: false,
            default: () => ''
        },
        ratio: {
            type: Number as PropType<number>,
            required: false,
            default: () => 1
        },
        color: {
            type: Number as PropType<number>,
            required: false,
            default: () => 1
        },
        alignment: {
            type: String as PropType<'center' | 'top' | 'bottom'>,
            required: false,
            default: () => 'center'
        },
        overlapcall: {
            type: Number as PropType<number>,
            required: false,
            default: () => -1
        },
        amount: {
            type: Number as PropType<number>,
            required: false,
            default: () => 0
        },
        confirmedBet: {
            type: Number as PropType<number>,
            required: false,
            default: () => 0
        },
        fTempBet: {
            type: Number as PropType<number>,
            required: false,
            default: () => 0
        },
        betType: {
            type: String as PropType<Fan2BetType>,
            required: true
        },
        showAnimation: {
            type: Boolean as PropType<boolean>,
            required: false,
            default: () => false
        }
    },
    setup(props, { emit }) {
        const emitter = inject('emitter') as Emitter
        const isMouseDown = ref(false)

        onBeforeMount(() => {
            emitter.on(EventBuss.BET_MOUSE_UP, handleMouseUp)
        })

        onBeforeUnmount(() => {
            emitter.off(EventBuss.BET_MOUSE_UP, handleMouseUp)
        })

        const handleMouseUp = () => {
            isMouseDown.value = false
        }

        const onClicked = (e: MouseEvent) => {
            if (isClicked(e)) {
                console.log(props.label)
                isMouseDown.value = true
                // emitter.emit(EventBuss.TOAST_MESSAGE, props.label)
                placeBet()
            } else {
                if (props.overlapcall !== -1) {
                    emit('on-overlaped', props.overlapcall)
                }
            }
        }

        const forceCliked = () => {
            // console.log(props.label)
            isMouseDown.value = true
            // emitter.emit(EventBuss.TOAST_MESSAGE, props.label)
            placeBet()
        }

        const placeBet = () => {
            emit('place-bet', props.betType)
        }

        const amountOfBet = computed(
            () => props.amount + props.confirmedBet + props.fTempBet
        )

        return {
            onClicked,
            forceCliked,
            handleMouseUp,
            isMouseDown,
            amountOfBet
        }
    }
})
