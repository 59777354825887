import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, withModifiers as _withModifiers, createBlock as _createBlock, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-66147814"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "fantan-switch-table-root" }
const _hoisted_2 = { class: "fantan-switch-table-wrap" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_switch_table_item = _resolveComponent("switch-table-item")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.desks, (desk) => {
        return (_openBlock(), _createBlock(_component_switch_table_item, {
          class: "item",
          selected: _ctx.selectedTable,
          mdata: desk,
          key: desk.tableNo,
          onSelectDesk: _ctx.enterDesk,
          onClick: _withModifiers(($event: any) => (_ctx.handleSelectTable(desk.tableNo)), ["stop"])
        }, null, 8, ["selected", "mdata", "onSelectDesk", "onClick"]))
      }), 128))
    ])
  ]))
}