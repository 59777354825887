export class RoadMapItem {
    type = 0
    size = 0
    values: Array<number> = []

    constructor(type = 0) {
        this.type = type
    }

    addSize(v: number) {
        this.size += 1
        this.values.push(v)
    }

    pop() {
        if (this.size > 0) {
            this.size -= 1
            this.values.pop()
        }
    }
}
