import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withModifiers as _withModifiers, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-621bb86f"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "fantan-switch-table-item-root" }
const _hoisted_2 = { class: "header" }
const _hoisted_3 = { class: "table-no" }
const _hoisted_4 = { class: "timer" }
const _hoisted_5 = { class: "round" }
const _hoisted_6 = { class: "odd" }
const _hoisted_7 = { class: "even" }
const _hoisted_8 = { class: "one" }
const _hoisted_9 = { class: "two" }
const _hoisted_10 = { class: "three" }
const _hoisted_11 = { class: "four" }
const _hoisted_12 = { class: "five" }
const _hoisted_13 = { class: "map-wrap" }
const _hoisted_14 = {
  key: 0,
  class: "limit-wrap"
}
const _hoisted_15 = { class: "title" }
const _hoisted_16 = { class: "limits" }
const _hoisted_17 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_r_map = _resolveComponent("r-map")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, "经典番摊" + _toDisplayString(_ctx.mdata.tableNo) + "台", 1),
      _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.currentCount === -1 ? '' : _ctx.currentCount), 1),
      _createElementVNode("div", _hoisted_5, "局数:" + _toDisplayString(_ctx.mapData.round), 1),
      _createElementVNode("div", _hoisted_6, "单:" + _toDisplayString(_ctx.mapData.evenCount), 1),
      _createElementVNode("div", _hoisted_7, "双:" + _toDisplayString(_ctx.mapData.oddCount), 1),
      _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.mapData.oneCount), 1),
      _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.mapData.twoCount), 1),
      _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.mapData.threeCount), 1),
      _createElementVNode("div", _hoisted_11, _toDisplayString(_ctx.mapData.fourCount), 1),
      _createElementVNode("div", _hoisted_12, _toDisplayString(_ctx.mapData.fiveCount), 1)
    ]),
    _createElementVNode("div", _hoisted_13, [
      _createVNode(_component_r_map, {
        class: "map",
        "map-data": _ctx.mdata.result,
        "bead-row-count": 6,
        onRoadMapData: _ctx.OnMapDataChange
      }, null, 8, ["map-data", "onRoadMapData"])
    ]),
    (_ctx.selected === _ctx.mdata.tableNo)
      ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
          _createElementVNode("div", _hoisted_15, _toDisplayString(_ctx.t('selectlimit')), 1),
          _createElementVNode("div", _hoisted_16, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.limits, (limit) => {
              return (_openBlock(), _createElementBlock("div", {
                class: "item btn",
                key: limit.Id,
                onClick: _withModifiers(($event: any) => (_ctx.enterTable(limit)), ["stop"])
              }, [
                _createElementVNode("span", null, _toDisplayString(_ctx.t('minlimit')) + ": " + _toDisplayString(limit.Min), 1),
                _createElementVNode("span", null, _toDisplayString(_ctx.t('maxlimit')) + ": " + _toDisplayString(limit.Max), 1)
              ], 8, _hoisted_17))
            }), 128))
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}