
// import { useStore } from '@/store'
import {
    defineComponent,
    onBeforeUnmount,
    onMounted,
    PropType,
    ref,
    watch
} from 'vue'
import { useI18n } from 'vue-i18n'
import { drawRoadMap, drawSingleGridMap, parseRoadMap } from './utils'
import { RoadMapData } from './datamodel/RoadMapData'
import { RoadType } from './datamodel/types'

export default defineComponent({
    name: 'road-map',
    emits: ['road-map-data', 'road-result-preview'],
    props: {
        enableClicked: {
            default: false,
            required: false,
            type: Boolean as PropType<boolean>
        },
        mapData: {
            required: true,
            type: String as PropType<string>
        },
        gameType: {
            default: () => 1,
            type: Number
        },
        enablePrediction: {
            default: () => false,
            type: Boolean
        },
        beadRowCount: {
            default: () => 13,
            type: Number
        },
        display: {
            default: () => 0,
            type: Number
        }
    },
    setup(props, context) {
        const { t } = useI18n()
        // const store = useStore()
        const readyToRender = ref<boolean>(false)
        const mapCanvas = ref()
        const predictionTimer = ref<number | boolean>(false)
        const predictionType = ref<number>(0)
        const predictionCount = ref<number>(9)
        const predictionBlink = ref<number>(9)

        const roadMapData = ref<RoadMapData>(new RoadMapData())

        let beadsArray: { index: number; path2D: Path2D }[] | undefined

        const drawMap = () => {
            beadsArray = []
            if (!readyToRender.value) return

            // const display = props.display
            // const canvas = mapCanvas.value as HTMLCanvasElement

            // const prop = drawSingleGridMap(canvas)
            // drawBeadOnly(roadMapData.value)

            if (props.display === 0 || props.display === 1) {
                // default
                drawBeadAndBig(roadMapData.value)
            }
        }

        onMounted(() => {
            readyToRender.value = true

            if (props.enableClicked) {
                mapCanvas.value.addEventListener('click', handleClickEvent)
            }
            drawMap()
        })

        onBeforeUnmount(() => {
            if (props.enableClicked) {
                mapCanvas.value.removeEventListener('click', handleClickEvent)
            }
        })

        const handleClickEvent = (e: MouseEvent) => {
            if (mapCanvas.value && beadsArray) {
                const ctx = (mapCanvas.value as HTMLCanvasElement).getContext(
                    '2d'
                )

                const dpi = window.devicePixelRatio

                beadsArray.forEach((beadsRef) => {
                    if (
                        ctx?.isPointInPath(
                            beadsRef.path2D,
                            e.offsetX * dpi,
                            e.offsetY * dpi
                        )
                    ) {
                        context.emit('road-result-preview', beadsRef.index)
                    }
                })
            }
        }

        const drawBeadOnly = (data: RoadMapData) => {
            const canvas = mapCanvas.value as HTMLCanvasElement
            const prop = drawSingleGridMap(canvas)

            if (prop && data) {
                const { beads, bigs } = data
                prop.colNum = props.beadRowCount
                prop.lineWidth = 2
                if (props.display === 1) {
                    drawRoadMap(prop, beads, RoadType.BEAD, 0, 0, 3, t)
                } else {
                    drawRoadMap(prop, beads, RoadType.BEAD, 0, 0, 3, t, 2)
                }
                // const offset = prop.colWidth * props.beadRowCount
                // prop.colWidth = prop.colWidth * 2
                // prop.colNum = 9
                // drawRoadMap(prop, bigs, RoadType.BIG, 0, offset, 3, t)
            }
        }

        const drawBeadAndBig = (data: RoadMapData) => {
            const canvas = mapCanvas.value as HTMLCanvasElement
            const prop = drawSingleGridMap(canvas)

            if (prop && data) {
                const { beads, bigs } = data
                prop.colNum = props.beadRowCount
                prop.lineWidth = 2
                if (props.display === 1) {
                    drawRoadMap(prop, beads, RoadType.BEAD, 0, 0, 3, t)
                } else {
                    drawRoadMap(prop, beads, RoadType.BEAD, 0, 0, 3, t, 2)
                }

                const cW = prop.width
                const cCols = Math.floor(cW / prop.colWidth)
                const rCols = cCols - (props.beadRowCount + 1)
                if (rCols > 0) {
                    const offset = prop.colWidth * (props.beadRowCount + 1)
                    prop.colWidth = prop.colWidth * 2
                    prop.colNum = rCols
                    drawRoadMap(prop, bigs, RoadType.BIG, 0, offset, 3, t)
                }
            }
        }

        // const lang = computed((): string => store.getters['lang'])
        //#region Watchers

        watch(
            () => props.mapData,
            (value) => {
                roadMapData.value = parseRoadMap(value)

                if (props.enablePrediction) {
                    context.emit('road-map-data', roadMapData.value)
                } else {
                    context.emit('road-map-data', roadMapData.value)
                }

                if (
                    readyToRender.value &&
                    typeof predictionTimer.value !== 'number'
                ) {
                    drawMap()
                }
            },
            { immediate: true }
        )

        watch(
            () => [props.display, props.beadRowCount],
            () => {
                drawMap()
            }
        )

        //#endregion
        return {
            mapCanvas,
            drawMap
        }
    }
})
