
import {
    Emitter,
    SmallToast,
    computed,
    defineComponent,
    inject,
    onMounted,
    ref
} from 'vue'
import { abbreviateNumber } from '@/utils'
import { useStore } from '@/store'
import { AppMutation } from '@/store/types'
import CtrlBtnClickDbClick from '@/components/custom-controls/ctrl-btn-click-dbclick.vue'
import { EventBuss } from '@/types/global'

export default defineComponent({
    components: {
        CtrlBtnClickDbClick
    },
    setup() {
        const emitter = inject('emitter') as Emitter
        const store = useStore()
        const editMode = ref<boolean>(false)
        const input = ref<HTMLInputElement>()
        const inputValue = ref<string>()
        let setFocusTimeOut: boolean | number = false
        const scrollNo = ref<number>(0)
        const toShortValue = (value: number) => {
            return abbreviateNumber(value)
        }

        onMounted(() => {
            clearSetfocusTimeout()
        })

        const selectThisChip = (_index: number) => {
            if (_index !== currentChip.value)
                store.commit(AppMutation.SET_CURRENT_CHIPS, _index)
        }

        const customDbClicked = () => {
            editMode.value = true
            clearSetfocusTimeout()
            setFocusTimeOut = setTimeout(() => {
                input.value?.focus()
                clearSetfocusTimeout()
            }, 250)
        }

        const clearSetfocusTimeout = () => {
            if (typeof setFocusTimeOut === 'number') {
                clearTimeout(setFocusTimeOut)
                setFocusTimeOut = false
            }
        }

        const customClicked = () => {
            if (customChip.value > 0) {
                store.commit(AppMutation.SET_CURRENT_CHIPS, 22)
            }
        }

        const onCapchaKeydown = (event: KeyboardEvent) => {
            if (event.key.toLowerCase() !== 'enter') {
                if (event.key.toLowerCase() === 'e') {
                    event.preventDefault()
                    return
                }

                if (
                    document.getSelection()?.toString() ===
                    inputValue.value?.toString()
                ) {
                    return
                }
            }

            if (event.key.toLowerCase() === 'enter') {
                exitEditMode()
            }
        }

        const exitEditMode = () => {
            editMode.value = false
            const intValue = parseInt(inputValue.value?.toString() || '')

            if (intValue > 100) {
                store.commit(AppMutation.SET_CUSTOM_CHIP, intValue)
                inputValue.value = ''
                store.commit(AppMutation.SET_CURRENT_CHIPS, 22)
            } else {
                emitter.emit(EventBuss.TOAST_MESSAGE, '金额无效')
            }
        }

        const chips = computed((): number[] => store.getters['chips'])
        const currentChip = computed((): number => store.getters['currentChip'])
        const customChip = computed(() => store.getters['customChip'])

        return {
            toShortValue,
            selectThisChip,
            chips,
            currentChip,
            editMode,
            customChip,
            input,
            inputValue,
            scrollNo,
            customDbClicked,
            customClicked,
            exitEditMode,
            onCapchaKeydown
        }
    }
})
