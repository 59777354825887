export type Fan2BetType =
    | '123中'
    | '124中'
    | '134中'
    | '234中'
    | '34角'
    | '4连3'
    | '4正'
    | '4连1'
    | '14角'
    | '3连4'
    | '3正'
    | '3连2'
    | '4番'
    | '4连2'
    | '3连1'
    | '单'
    | '1番'
    | '3番'
    | '双'
    | '1连3'
    | '2连4'
    | '2番'
    | '1连4'
    | '1正'
    | '1连2'
    | '23角'
    | '2连3'
    | '2正'
    | '2连1'
    | '12角'
    | '1通23'
    | '1通24'
    | '1通34'
    | '3通12'
    | '3通14'
    | '3通24'
    | '2通13'
    | '2通14'
    | '2通34'
    | '4通12'
    | '4通13'
    | '4通23'

export function toFantan(value: Fan2BetType): string {
    switch (value) {
        case '123中':
            return 'f27'
        case '124中':
            return 'f28'
        case '134中':
            return 'f29'
        case '234中':
            return 'f30'
        case '34角':
            return 'f22'
        case '4连3':
            return 'f16'
        case '4正':
            return 'f26'
        case '4连1':
            return 'f14'
        case '14角':
            return 'f19'
        case '3连4':
            return 'f13'
        case '3正':
            return 'f25'
        case '3连2':
            return 'f12'
        case '4番':
            return 'f4'
        case '4连2':
            return 'f15'
        case '3连1':
            return 'f11'
        case '单':
            return 'f18'
        case '1番':
            return 'f1'
        case '3番':
            return 'f3'
        case '双':
            return 'f21'
        case '1连3':
            return 'f6'
        case '2连4':
            return 'f10'
        case '2番':
            return 'f2'
        case '1连4':
            return 'f7'
        case '1正':
            return 'f23'
        case '1连2':
            return 'f5'
        case '23角':
            return 'f20'
        case '2连3':
            return 'f9'
        case '2正':
            return 'f24'
        case '2连1':
            return 'f8'
        case '12角':
            return 'f17'
        case '1通23':
            return 'f31'
        case '1通24':
            return 'f32'
        case '1通34':
            return 'f33'
        case '3通12':
            return 'f37'
        case '3通14':
            return 'f38'
        case '3通24':
            return 'f39'
        case '2通13':
            return 'f34'
        case '2通14':
            return 'f35'
        case '2通34':
            return 'f36'
        case '4通12':
            return 'f40'
        case '4通13':
            return 'f41'
        case '4通23':
            return 'f42'
    }

    return ''
}
