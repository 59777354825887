import { BaseRoadmapItem } from './BaseRoadmapItem'
import { TypePair } from './types'

export class BigItem extends BaseRoadmapItem {
    value: number

    constructor(type: number, x: number, y: number, value: number) {
        super(type, x, y)
        this.value = value
    }
}
