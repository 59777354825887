
import {
    Emitter,
    computed,
    defineComponent,
    inject,
    onBeforeUnmount,
    onMounted,
    ref
} from 'vue'
import Item from './switch-table-item.vue'
import { ACT, EventBuss, OtherApiParams } from '@/types/global'
import { AppActions, AppMutation } from '@/store/types'
import { useStore } from '@/store'
import { fetch } from '@/api'
import { Desk } from '@/types/Desk'
import { ROUTES } from '@/router'
import { useRouter } from 'vue-router'
import { getValueOfKeyValue } from '@/utils/api'
import { useI18n } from 'vue-i18n'

export default defineComponent({
    emits: ['select-desk'],
    components: {
        'switch-table-item': Item
    },
    setup(props, { emit }) {
        const emitter = inject('emitter') as Emitter
        const store = useStore()
        const router = useRouter()
        const { t } = useI18n()

        const selectedTable = ref(-1)

        const desks = ref<Array<Desk>>([])

        let failureCounter = 0
        const PULL_INTERVAL = 3000
        let serviceTimeOut: number | boolean = false

        //#region  Vue Helpers
        onMounted(() => {
            loadData()
        })

        onBeforeUnmount(() => {
            stopPullingData()
        })
        //#endregion

        const loadData = (_forceReload = false) => {
            if (!_forceReload) store.commit(AppMutation.SHOW_LOADING)

            const _params = {
                ...OtherApiParams,
                act: ACT.MULTIPLE_DESK_INFO,
                sessionID: token.value,
                username: user.value,
                gameType: 3
            }

            store
                .dispatch(AppActions.FETCH_DATA, _params)
                .then((response) => {
                    failureCounter = 0
                    if (!_forceReload) store.commit(AppMutation.HIDE_LOADING)

                    if (response.includes('err=')) {
                        stopPullingData()
                        emitter.emit(EventBuss.SESSION_EXPIRED)
                    } else {
                        desks.value = Desk.ParseList(response)

                        if (response.includes('$UserPoint=')) {
                            const _userPointValuePair =
                                response.split('$')[desks.value.length]

                            const _coin = parseInt(
                                getValueOfKeyValue(_userPointValuePair) ?? '0'
                            )
                            store.commit(AppMutation.SET_COIN, _coin)
                        }

                        serviceTimeOut = setTimeout(() => {
                            loadData(true)
                        }, PULL_INTERVAL)
                    }
                })
                .catch(() => {
                    failureCounter += 1

                    if (!_forceReload) {
                        store.commit(AppMutation.HIDE_LOADING)
                        emitter.emit(EventBuss.TOAST_MESSAGE, t('networkerror'))
                    }

                    if (failureCounter > 3) {
                        stopPullingData
                        emitter.emit(EventBuss.TOAST_MESSAGE, t('networkerror'))

                        store.commit(AppMutation.CLEAR_ALL)
                        router.push({ name: ROUTES.LOGIN })
                        return
                    }

                    serviceTimeOut = setTimeout(() => {
                        loadData(true)
                    }, PULL_INTERVAL)
                })
        }

        const handleSelectTable = (tableNo: number) => {
            if (tableNo === selectedTable.value) selectedTable.value = -1
            else selectedTable.value = tableNo
        }

        const enterDesk = (e: Desk) => {
            emit('select-desk', e)
        }

        const stopPullingData = () => {
            if (typeof serviceTimeOut === 'number') {
                clearTimeout(serviceTimeOut)
                serviceTimeOut = false
            }
        }

        const user = computed(() => store.getters['user'])
        const token = computed(() => store.getters['token'])

        return {
            desks,
            selectedTable,
            handleSelectTable,
            enterDesk
        }
    }
})
