export class BeadItems {
    x: number
    y: number
    type: number
    value = 0
    pair = false
    constructor(x: number, y: number, type: number, value = 0) {
        this.x = x
        this.y = y
        this.type = type
        this.value = value
    }

    setPair(isPair: boolean) {
        this.pair = isPair
    }
}
